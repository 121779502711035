import React, { useEffect, useState } from 'react';
import { Slide } from 'react-awesome-reveal';
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebaseConfig"

import Recommend from '../../layout/Recommend'
import Footer from '../../layout/Footer'

import './youhand.css'
import streamlit_icon from '../../../images/streamlit_icon.png'
import mac_icon from '../../../images/mac-badge.png'
import win_icon from '../../../images/window-badge.png'
import youhand1 from '../../../images/youhand1.png'
import youhand_features from '../../../images/youhand_features.png'
import mediapipe_icon from '../../../images/mediapipe_icon.png'
import sklearn_icon from '../../../images/sklearn_icon.png'
import opencv_icon from '../../../images/opencv_icon.png'
import streamlit_logo from '../../../images/streamlit_logo.png'
import default_gestures from '../../../images/default_gestures.png'

const Youhand = () => {
	const [showList, setShowList] = useState(false)

	useEffect(() => {
		logEvent(analytics, 'screen_view', {
			firebase_screen: "YouHand",
			firebase_screen_class: "YouHand"
		});
	}, [])

	const showListContent = () => {
		setShowList(!showList)
	}

	const handleDownload = (name) => {
		logEvent(analytics, 'youhand_download_' + name);
		document.getElementById("youhand-installation").scrollIntoView();
	}

	const vidWidth = Math.min(Math.floor(window.innerWidth * 0.8), 900);
	const vidHeight = Math.floor(vidWidth * 720/1280);
	return (
		<div className="overflow-x-hidden">
			<div className="youhand-wallpaper overflow-x-hidden">
				<Slide direction='left' duration={1500} triggerOnce={false} className="full-width">
					<div className="youhand-title">
						Project YouHand
					</div>
				</Slide>
				<Slide direction='right' duration={1500} triggerOnce={false} className="full-width">
					<div className="youhand-subtitle">
						Continual learning for gesture recognition
					</div>
				</Slide>

				<Slide direction='up' duration={1500} triggerOnce={false} className="full-width youhand-descript">
					<div className="row">
					<div className="col s12 m4 center">
						<span className="youhand-em">Role</span> Full-stack developer
					</div>
					<div className="col s12 m4 center">
						<span className="youhand-em">Context</span> Innovation
					</div>
					<div className="col s12 m4 center">
						<span className="youhand-em">Period</span> Fall 2021
					</div>
					</div>
				</Slide>
			</div>
			<div className="youhand-intro">
				<div className="youhand-intro-container">
					<div className="youhand-intro-title"
						data-aos="flip-up"
						data-aos-delay="200"
					>
						Introduction
					</div>
					<div className="youhand-intro-content"
						data-aos="fade-up"
						data-aos-delay="200"
					>
						Youhand is an innovative solution for <span className="amber-text">hand gesture recognition</span>. The system allows users to add an endless number of <span className="amber-text">custom gestures</span> which then can be used to control videos on <span className="amber-text">YouTube</span> platform.
					</div>
					<div className="center"
						data-aos="flip-right"
						data-aos-delay="300"
					>
						<a className="pulse-btn youhand-intro-streamlit"
							href="https://share.streamlit.io/minhanh29/youhand/webapp/YouHandWeb.py"
							target="_blank"
							onClick={() => logEvent(analytics, "youhand_open_streamlit")}
						>
							Test on Streamlit  <img src={streamlit_icon} alt="streamlit icon" />
						</a>
					</div>
				</div>
			</div>
			<div className="youhand-download">
				<div className="youhand-intro-content-italic"
					data-aos="flip-up"
					data-aos-delay="200"
				>
						Download desktop app for better stability
				</div>
				<div className="youhand-download-btn-container row center">
					<div
						className="col s12 m6 l6"
						data-aos="fade-right"
						data-aos-delay="200"
					>
						<a href="https://drive.google.com/file/d/17qE5NfmCJ0V0q5GQVp_QNsovKOg3Bv4m/view?usp=sharing" target="_blank">
						<img className="youhand-download-btn"
							src={mac_icon}
							alt="Download for mac"
							onClick={() => handleDownload("mac")}
						/>
						</a>
					</div>
					<div
						className="col s12 m6 l6"
						data-aos="fade-left"
						data-aos-delay="200"
					>
						<a href="https://drive.google.com/file/d/1a3qb3VThRVJW3K_LY2uPSvw1jFamj2oo/view?usp=sharing" target="_blank">
						<img className="youhand-download-btn"
							src={win_icon}
							alt="Download for windows"
							onClick={() => handleDownload("window")}
						/>
						</a>
					</div>
					<div className="center"
						data-aos="flip-up"
						data-aos-delay="200"
					>

					</div>
				</div>
			</div>


			<div className='youhand-title-container'>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					INFORMATION & ANALYSIS
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Project Details
				</div>
			</div>

			<div className="row youhand-section-container">
				<div className="col l5 m12"
					data-aos="fade-down"
					data-aos-delay="200"
				>
					<div className="section-slogan">
						A customizable artificial intelligence system
					</div>
					<div className="section-slogan-divider-left"></div>
					<div className="section-content">
						Using <span className="youhand-em-2">hand gesture</span> is one of the most practical and intuitive solutions for computer-human interaction. Most gesture recognition systems only work with a <span className="youhand-em-2">fixed set</span> of pre-defined gestures. However, many users want to <span className="youhand-em-2">customize</span> the system to match their habits and expectations. <span className="youhand-em-2">YouHand</span> was born to make that possible.
					</div>
				</div>
				<div className="col l7 m12 center"
					data-aos="fade-up"
					data-aos-delay="200"
				>
					<img className="youhand-section-img" src={youhand1} alt="YouHand Interface" />
				</div>
			</div>

			<div className="row youhand-section-container">
				<div className="col l7 m12 center hide-on-med-and-down"
					data-aos="fade-up"
					data-aos-delay="200"
				>
					<img className="youhand-section-img padding-right-20" src={youhand_features} alt="YouHand Interface" />
				</div>

				<div className="col l5 m12"
					data-aos="fade-down"
					data-aos-delay="200"
				>
					<div className="section-slogan text-right">
						A user-friendly touchless interface
					</div>
					<div className="section-slogan-divider">
						<div className="section-slogan-divider-right"></div>
					</div>
					<div className="section-content">
						YouHand provides you all basic features to control YouTube videos <span className="youhand-em-2">touchlessly</span> with your hand, including play, stop, forward/backward, adjust volume, fullscreen, and skip ads.<br/><br/>
						The app is available for both <span className="youhand-em-2">website</span> and <span className="youhand-em-2">desktop</span>. It is recommended to use the desktop app version to get full features and  better experience.
					</div>
				</div>

				<div className="col l7 m12 center hide-on-large-only margin-top-40"
					data-aos="fade-up"
					data-aos-delay="200"
				>
					<img className="youhand-section-img" src={youhand_features} alt="YouHand Interface" />
				</div>
			</div>

			<div className='youhand-title-container'>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					TECHNICAL INFORMATION
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Technology
				</div>
			</div>

			<div className="row youhand-section-container"
				data-aos="fade-up"
				data-aos-delay="200"
			>
				<div className="col l3 m6 s12 center">
					<a href="https://google.github.io/mediapipe/" target="_blank">
						<img className="youhand-logo-img" src={mediapipe_icon} alt="mediapipe icon" />
					</a>
				</div>
				<div className="col l3 m6 s12 center">
					<a href="https://opencv.org/" target="_blank">
						<img className="youhand-logo-img height-70" src={opencv_icon} alt="opencv icon" />
					</a>
				</div>
				<div className="col l3 m6 s12 center">
					<a href="https://scikit-learn.org/stable/" target="_blank">
						<img className="youhand-logo-img" src={sklearn_icon} alt="mediapipe icon" />
					</a>
				</div>
				<div className="col l3 m6 s12 center">
					<a href="https://docs.python.org/3/library/tkinter.html" target="_blank">
						<img className="youhand-logo-img" src={streamlit_logo} alt="streamlit icon" />
					</a>
				</div>
			</div>

			<div className='youhand-title-container no-margin-top'>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					SHOWTIME
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Demonstration
				</div>
			</div>

			<div className="center"
					data-aos="fade-down"
					data-aos-delay="200"
			>
				<iframe width={vidWidth} height={vidHeight} src="https://www.youtube.com/embed/_MLDOVnQDgY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>

			<div className='youhand-title-container' id="youhand-installation">
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					INSTRUCTIONS & GUIDES
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Installation
				</div>
			</div>

			<ul className="container youhand-install-container"
				data-aos='fade-up'
				data-aos-delay="200"
			>
				<li className="youhand-list-item">
					<span className="youhand-list-bullet">1</span> Download the installer
				</li>
				<li className="youhand-list-item">
					<span className="youhand-list-bullet">2</span> Check your chrome version by going to Settings -&gt; About Chrome
				</li>
				<li className="youhand-list-item">
					<span className="youhand-list-bullet">3</span> Download the coressponding chrome driver on Google
				</li>
				<li className="youhand-list-item cursor-pointer" onClick={showListContent}>
					<span className="youhand-list-bullet">4</span> Paste the chromedriver to the driver folder below <i className="youhand-icon material-icons">{showList ? "keyboard_arrow_down" : "keyboard_arrow_right" }</i><br/>
					{showList ?
					<div className="youhand-sub-list">
					<strong>Mac:</strong> Right click on YouHand application -&gt; Show Package Contents -&gt; Contents -&gt; MacOS -&gt; driver.<br/>
					<strong>Windows:</strong> Right click on YouHand application on desktop -&gt; Properties -&gt; Shortcut -&gt; Open file location -&gt; driver. <br/>
					(Watch the demo video for more details)
					</div>
					: <div></div>}
				</li>
			</ul>


			<div className='youhand-title-container '>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					EXTRA INFORMATION
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Built-In Gestures
				</div>
			</div>

			<div className="container center"
				data-aos='fade-up'
				data-aos-delay="200"
			>
				<img className="youhand-gestures" src={default_gestures} alt="built-in gestures" />
			</div>

			<div className="vertical-line"></div>

			<Recommend subtitle="NEXT PROJECT" title="Optimal Meeting Point" to="/omp" />
			<Footer />
		</div>
	)
}


export default Youhand
