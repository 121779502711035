import React from 'react';
import { Link } from 'react-router-dom'

import './recommend.css'
import arrowImg from '../../images/right-arrow.png'

const Recommend = (props) => {
	return (
		<Link to={props.to} className="rec-container">
			<div className="rec-inner-container">
				<div className="section-sub-title rec-subtitle">
					{props.subtitle}
				</div>
				<div className="section-title rec-margin rec-title">
					{props.title}
				</div>
					<img className="rec-arrow" src={arrowImg} alt='My arrow' />
			</div>

			<div className="rec-container-overlay black rec-trans-fast"></div>
			<div className="rec-container-overlay amber darken-3 rec-trans-slow"></div>
		</Link>
	)
}

export default Recommend

