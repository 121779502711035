import React, { useEffect } from 'react';
import { Slide } from 'react-awesome-reveal';
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebaseConfig"

import Recommend from '../../layout/Recommend'
import Footer from '../../layout/Footer'

import './sudoku.css'
import sudoku_mockup from '../../../images/sudoku_mockup.png'


const Sudoku = () => {

	useEffect(() => {
		logEvent(analytics, 'screen_view', {
			firebase_screen: "SudokuSolver",
			firebase_screen_class: "SudokuSolver"
		});
	}, [])

	const vidWidth = Math.min(Math.floor(window.innerWidth * 0.8), 700);
	const vidHeight = Math.floor(vidWidth * 720/1280);
	return (
		<div className="overflow-x-hidden">
			<div className="sudoku-wallpaper overflow-x-hidden row">
				<div className="col s12 m12 l6">
				<Slide direction='left' duration={1500} triggerOnce={true} className="full-width">
					<div className="sudoku-title">
						Sudoku Solver
					</div>
				</Slide>
				<Slide direction='left' duration={1500} triggerOnce={true} className="full-width">
					<div className="sudoku-subtitle">
						An improved sudoku solving algorithm
					</div>
				</Slide>
				<Slide direction="up" duration={1500} triggerOnce={true} className="center">
					<a className="sudoku-intro-web pulse-btn" href="https://github.com/minhanh29/SudokuAI" target="_blank"
						onClick={() => logEvent(analytics, "sudoku_open_code")}
					>
						View on GitHub
					</a>
				</Slide>
				</div>

				<div className="col s12 m12 l6 hide-on-med-and-down">
					<Slide direction='right' duration={1500} triggerOnce={true} className="full-width">
						<img className="sudoku-mockup" src={sudoku_mockup} alt="sudoku image"/>
					</Slide>
				</div>

				<Slide direction='up' duration={1500} triggerOnce={true} className="full-width sudoku-descript">
					<div className="row">
					<div className="col s12 m4 center">
						<span className="sudoku-em">Role</span> Full-stack developer
					</div>
					<div className="col s12 m4 center">
						<span className="sudoku-em">Context</span> Application
					</div>
					<div className="col s12 m4 center">
						<span className="sudoku-em">Period</span> End 2020
					</div>
					</div>
				</Slide>
			</div>
			<div className='sudoku-title-container'>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-once="true"
					data-aos-delay="200"
				>
					SHOW CASE
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-once="true"
					data-aos-delay="200"
				>
					DEMO
				</div>
			</div>

			<div className="center">
				<iframe width={vidWidth} height={vidHeight} src="https://www.youtube.com/embed/i_O8kpex0FQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
			<Recommend subtitle="NEXT PROJECT" title="Timely" to="/timely" />
			<Footer />
		</div>
	)
}

export default Sudoku
