import React, { Component } from 'react'
import './sum_project.css'
import ProjectCard from './ProjectCard'

const projects = [
	{
		direction: false,
		title: "Image Text Editor",
		subtitle: "Edit texts on photos and digital images",
		number: "01",
		background: "textswap",
		to: "/image-text-editor",
	},
	{
		direction: true,
		title: "Project YouHand",
		subtitle: "Continual learning for gesture recognition",
		number: "02",
		background: "hand-recog",
		to: "/youhand",
	},
	{
		directio: false,
		title: "Optimal Meeting Point",
		subtitle: "Find optimal locations for hanging out",
		number: "03",
		background: "omp",
		to: "/omp",
	},
	{
		direction: true,
		title: "Sudoku Solver",
		subtitle: "An improved sudoku solving algorithm",
		number: "04",
		background: "sudoku",
		to: '/sudoku',
	},
	{
		direction: false,
		title: "Timely Mobile",
		subtitle: "Create your conflict-free timetables",
		number: "05",
		background: "timely",
		to: '/timely',
	}
]

class SumProject extends Component {
	render() {
		return (
			<div className="my-project-container">
				<div className='title-container'>
					<div
						className='section-sub-title'
						data-aos="flip-down"
						data-aos-delay="200"
					>
						LATEST WORK
					</div>
					<div
						className='section-title'
						data-aos='flip-down'
						data-aos-delay="200"
					>
						My Projects
					</div>
				</div>

				{projects.map((item, index) => (
					<ProjectCard
						key={index}
						{...item}
					/>
				))}
			</div>
		)
	}
}

export default SumProject
