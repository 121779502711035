import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	textList: [],
	selectedTextId: -1,
}

export const textswapSlice = createSlice({
	name: "textswap",
	initialState,
	reducers: {
		addTexts: (state, action) => {
			state.textList = [
				...state.textList,
				...action.payload
			]
		},
		updateAttribute: (state, action) => {
			const { id, value } = action.payload
			const index = state.textList.findIndex(item => item.id === id)
			if (index !== -1) {
				state.textList[index] = value
			}
		},
		removeText: (state, action) => {
			if (state.textList.length == 0 || state.selectedTextId == -1)
				return

			state.textList = state.textList.filter(item => item.id !== state.selectedTextId)
			state.selectedTextId = -1
		},
		removeFromHistory: (state, action) => {
			if (state.textList.length == 0)
				return

			const historyOrder = action.payload
			state.textList = state.textList.filter(item => item.historyOrder !== historyOrder)
		},
		setSelectedTextId: (state, action) => {
			state.selectedTextId = action.payload
		},
		resetTextList: (state) => {
			state.selectedTextId = -1;
			state.textList = []
		}
	}
});

export const selectTextswap = state => state.textswap

export const { addTexts, updateAttribute, removeText, removeFromHistory, setSelectedTextId, resetTextList } = textswapSlice.actions

export default textswapSlice.reducer
