import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'

import logo from '../../images/logo_small.png'

class NavBar extends Component {
	state = {
		openMenu: false
	}

	toggleMenu = () => {
		// we are gonna close it
		if (this.state.openMenu)
		{
			this.enableScroll()
		}
		// we are gonna open it
		else
		{
			this.disableScroll()
		}
		this.setState(prevState => ({openMenu: !prevState.openMenu}))
	}

	disableScroll = () => {
		// Get the current page scroll position
		const scrollTop = window.pageYOffset;
		const scrollLeft = window.pageXOffset;

		// if any scroll is attempted, set this to the previous value
		window.onscroll = function() {
			window.scrollTo(scrollLeft, scrollTop);
		};
	}

	enableScroll = () => {
		window.onscroll = () => {};
	}

	render() {
		const menuClass = this.state.openMenu ? 'menu opened hide-on-large-only' : 'menu hide-on-large-only';
		const sideNavClass = this.state.openMenu ? 'my-sidenav show hide-on-large-only' : 'my-sidenav not-show hide-on-large-only';
		return (
			<div>
				<nav
					className="nav-wrapper my-nav-wrapper transparent"
					data-aos="fade-down"
					data-aos-delay="1500"
				>
					<div className="container">
						<Link to="/" className="my-brand-logo">
							<img className="responsive-img inline valign logo-img" src={logo} />
						</Link>

						<ul className="right hide-on-med-and-down text-med">
							<li><NavLink className="nav-item my-nav-item" exact to='/'>Home</NavLink></li>
							<li><NavLink className="nav-item my-nav-item" to='/#my-projects'>My Projects</NavLink></li>
							<li><NavLink className="nav-item my-nav-item" to='/contact'>Contact</NavLink></li>
						</ul>

						<a className={menuClass} onClick={this.toggleMenu} aria-label="Main Menu" aria-expanded={this.state.openMenu}>
							<svg width="100" height="100" viewBox="0 0 100 100">
							<path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
							<path className="line line2" d="M 20,50 H 80" />
							<path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
							</svg>
						</a>
					</div>
				</nav>

				<ul className={sideNavClass}>
					<NavLink onClick={this.toggleMenu} className="sidenav-item center" exact to='/'>Home</NavLink>
					<NavLink onClick={this.toggleMenu} className="sidenav-item center" to='/#my-projects'>My Projects</NavLink>
					<NavLink onClick={this.toggleMenu} className="sidenav-item center" to='/contact'>Contact</NavLink>
				</ul>
			</div>
		)
	}
}

export default NavBar
