import Konva from "konva"

const accentReg = /[àáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ]/i

const getTokensInString = text => {
	if (typeof text === "string") {
		var result = [];
		var tokens = text.split(" ");
		for (var i = 0; i < tokens.length; i++) {
			if (tokens[i].length > 0) {
				result.push(tokens[i]);
			}
		}
		return result;
	}
	return [];
};

const hasBrokenWords = (sourceTokens, renderLines) => {
	var combined = "";
	for (var i = 0; i < renderLines.length; i++) {
		combined += (i === 0 ? "" : " ") + renderLines[i].text;
	}

	var a = sourceTokens;
	var b = getTokensInString(combined);

	if (a.length !== b.length) {
		return true;
	}

	for (var i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) {
			return true;
		}
	}

	return false;
};

export const findFontSize = (text, width, height, align, fontFamily) => {
	let textNode = new Konva.Text({
		text: text,
		width, align,
		verticalAlign: "middle",
		wrap: "word",
		fontFamily: fontFamily,
		fontSize: 120
	})

	let sourceTokens = getTokensInString(text);
	let brokenWords = hasBrokenWords(sourceTokens, textNode.textArr);
	let scale = accentReg.test(text) ? 1.2 : 1
	while (textNode.height() * scale > height || brokenWords) {
		textNode.fontSize(textNode.fontSize() - 1)
		brokenWords = hasBrokenWords(sourceTokens, textNode.textArr);
	}

	return textNode.fontSize()
}

export const downloadURI = (uri, name) => {
	var link = document.createElement("a");
	link.download = name;
	link.href = uri;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
