import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './sum_project.css'

class ProjectCard extends Component {
	render() {
		const containerClass = "card-container " + this.props.background + " " + (this.props.direction ? "card-left" : "card-right")
		const containerAOS = this.props.direction ? "fade-left" : "fade-right"
		const contentClass = "card-content " + (this.props.direction ? "right text-right" : "left text-left")
		const numberClass = "card-number " + (this.props.direction ? "number-left" : "number-right")
		const address = this.props.to

		return (
			<Link to={address}
				key={this.props.to}
				className={containerClass}
				data-aos={containerAOS}
				data-aos-duration="800"
				data-aos-delay="100"
			>
				<div className={contentClass}>
					<div className="card-title">{this.props.title}</div>
					<div className="card-subtitle">{this.props.subtitle}</div>
					<div className="card-btn">
						Discover Now
					</div>
				</div>

				<div className="card-overlay"></div>
				<div className={numberClass}>{this.props.number}
					<div className="card-number-overlay">{this.props.number}</div>
				</div>
			</Link>
		)
	}
}

export default ProjectCard
