import React, { useEffect, useState } from 'react';
import M from "materialize-css"
import axios from "axios";
import { TbPerspective } from 'react-icons/tb';
import { useSelector, useDispatch } from 'react-redux'

import { updateAttribute, selectTextswap } from "../../../redux/reducers/textswapSlice"
import { addFont } from "../../../redux/reducers/fontSlice"
import { setEraserStrokeWidth, selectTool, setTool, TOOL_ERASER, TOOL_SELECT_RECTANGLE, TOOL_SELECT_PERSPECTIVE } from "../../../redux/reducers/toolSlice"

import './textswap.css'
import { API_ENDPOINT } from "./TextSwap"


const InspectorDescription = () => {
	const { description } = useSelector(selectTool)

	return (
		<div className="grey-text text-lighten-5">
			<div className="bold">{description.name}</div>
			<div className="inspector-description">
				<div dangerouslySetInnerHTML={{ __html: description.body }} />
			</div>
		</div>
	)
}


const Inspector = ({ translateText, setTranslateText }) => {
	const { toolId, eraserStrokeWidth } = useSelector(selectTool)
	const { textList, selectedTextId } = useSelector(selectTextswap)
	const chosenText = textList.find(item => item.id === selectedTextId, null)

	const [fontList, setFontList] = useState([])

	const dispatch = useDispatch()

	useEffect(() => {
		let elems = document.querySelectorAll('select');
		M.FormSelect.init(elems, {});

		fetchFontList()
	}, [])

	const fetchFontList = async () => {
		try {
			const res = await axios.get(API_ENDPOINT + "/fonts")
			setFontList(res.data.font_list)
		} catch (e) {
			window.alert(e.message)
		}
	}

	const handleTextProps = (attr) => {
		dispatch(updateAttribute({
			id: chosenText.id,
			value: {
				...chosenText,
				textProps: {
					...chosenText.textProps,
					...attr
				}
			}
		}))
	}

	const handleChangeFont = (e) => {
		const index = parseInt(e.target.value)
		dispatch(addFont([{
			fontId: index,
			fontName: fontList[index]
		}]))

		dispatch(updateAttribute({
			id: chosenText.id,
			value: {
				...chosenText,
				textProps: {
					...chosenText.textProps,
					fontFamily: fontList[index]
				},
				fontId: index,
			}
		}))
	}

	if (toolId === TOOL_SELECT_RECTANGLE || toolId === TOOL_SELECT_PERSPECTIVE) {
		return (
			<div className="inspector-container">
				<div className="inspector-title grey-text text-lighten-3">Selection Tools</div>
				<div className="inspector-section">
					<div
						className={"tool-btn " + (toolId === TOOL_SELECT_RECTANGLE ? "grey darken-3 tool-btn-border" : "")}
						onClick={() => dispatch(setTool(TOOL_SELECT_RECTANGLE))}
					>
						<i className="material-icons grey-text text-lighten-3 tool-icon">photo_size_select_large</i>
					</div>
					<div
						className={"margin-left-10 tool-btn " + (toolId === TOOL_SELECT_PERSPECTIVE ? "grey darken-3 tool-btn-border" : "")}
						onClick={() => dispatch(setTool(TOOL_SELECT_PERSPECTIVE))}
					>
						<i className="material-icons grey-text text-lighten-3 tool-icon"><TbPerspective /></i>
					</div>
				</div>

				<p className="inspector-checkbox">
					<label>
						<input
							type="checkbox"
							className="filled-in checkbox-orange"
							checked={translateText}
							onChange={() => setTranslateText(!translateText)}
						/>
						<span className="inspector-description grey-text text-lighten-5">
							Translate text to Vietnamese
						</span>
					</label>
				</p>

				<br />
				<InspectorDescription />
			</div>
		)
	}

	if (toolId === TOOL_ERASER) {
		return (
			<div className="inspector-container">
				<div className="inspector-section">
					<div className="inspector-title grey-text text-lighten-3">Stroke Size</div>

					<input
						id="eraser-size"
						type="number"
						step="0.25"
						className="white inspector-section-input-2"
						value={eraserStrokeWidth}
						onChange={e => dispatch(setEraserStrokeWidth(Math.abs(parseFloat(e.target.value))))}
					/>
				</div>

				<form action="#">
				<p className="range-field">
					<input type="range" id="erase-wdith" step="1" min="0" max="100" value={eraserStrokeWidth}
						onChange={e => dispatch(setEraserStrokeWidth(Math.abs(parseFloat(e.target.value))))}
					/>
				</p>
				</form>
				<br />
				<InspectorDescription />
			</div>
		)
	}

	if (!chosenText) {
		return <div className="inspector-container"><InspectorDescription /></div>
	}

	return (
		<div className="inspector-container">
			<div className="grey-text text-lighten-3">Text</div>
			<textarea
				className="inspector-textarea"
				value={chosenText.textProps.text}
				onChange={e => handleTextProps({ text: e.target.value })}
			></textarea>

			<div className="inspector-section">
				<div className="inspector-title grey-text text-lighten-3">Font</div>

				<select
					className="browser-default inspector-section-select"
					value={chosenText.fontId}
					onChange={handleChangeFont}
				>
					<option value={-1} disabled selected>
						Choose a font
					</option>
					{fontList.map((item, index) => (
						<option value={index}>{item}</option>
					))}
				</select>

				<input
					id="font_size"
					type="number"
					className="white inspector-section-input"
					value={chosenText.textProps.fontSize}
					onChange={e => handleTextProps({ fontSize: Math.abs(parseInt(e.target.value)) })}
				/>
			</div>

			<div className="inspector-section">
				<div className="inspector-title grey-text text-lighten-3">Line Height</div>

				<input
					id="line-height"
					type="number"
					step="0.25"
					className="white inspector-section-input-2"
					value={chosenText.textProps.lineHeight}
					onChange={e => handleTextProps({ lineHeight: Math.abs(parseFloat(e.target.value)) })}
				/>
			</div>

			<div className="inspector-section">
				<div className="inspector-title grey-text text-lighten-3">Color</div>
				<input
					type="color"
					id="textcolor"
					name="textcolor"
					value={chosenText.textProps.fill}
					onChange={e => handleTextProps({ fill: e.target.value })}
				/>
			</div>

			<div className="inspector-section">
				<div className="inspector-title grey-text text-lighten-3">Alignment</div>

				<div
					className={"inspector-align-btn " + (chosenText.textProps.align === "left" ? "grey darken-3 tool-btn-border" : "")}
					onClick={() => handleTextProps({ align: "left" })}
				>
					<i className="material-icons grey-text text-lighten-3 tool-icon">format_align_left</i>
				</div>

				<div
					className={"inspector-align-btn " + (chosenText.textProps.align === "center" ? "grey darken-3 tool-btn-border" : "")}
					onClick={() => handleTextProps({ align: "center" })}
				>
					<i className="material-icons grey-text text-lighten-3 tool-icon">format_align_center</i>
				</div>
				<div
					className={"inspector-align-btn " + (chosenText.textProps.align === "right" ? "grey darken-3 tool-btn-border" : "")}
					onClick={() => handleTextProps({ align: "right" })}
				>
					<i className="material-icons grey-text text-lighten-3 tool-icon">format_align_right</i>
				</div>
			</div>

		</div>
	)
}

export default Inspector
