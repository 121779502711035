import React, { Component } from 'react';
import { Slide } from 'react-awesome-reveal';
import { Link } from 'react-router-dom'

import './notfound.css'


class NotFound extends Component {

	render() {
		return (
			<div className="overflow-x-hidden">
				<div className="notfound-wallpaper overflow-x-hidden">
					<Slide direction='left' duration={1500} triggerOnce={true} className="full-width">
						<div className="notfound-title">
							404 - Page Not Found
						</div>
					</Slide>
					<Slide direction='left' duration={1500} triggerOnce={true} className="full-width">
						<div className="notfound-subtitle">
							Oops! It seems like you're in a wrong way
						</div>
					</Slide>
					<Slide direction="up" duration={1500} triggerOnce={true} className="center">
						<Link to="/" className="notfound-intro-web pulse-btn" >
							Go back home
						</Link>
					</Slide>
				</div>
			</div>
		)
	}
}

export default NotFound
