import React, { Component } from 'react'

import logo from '../../images/logo_small.png'

class Footer extends Component {
	goTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}

	render() {
		const year = (new Date()).getFullYear();
		return (
			<footer className="white-text center">
				<div className="footer-container">
					<img onClick={this.goTop} className="responsive logo-img go-top" src={logo} alt="logo" />
					<div className="go-top-icon">↑</div>
					<ul className="footer-nav row">
						<li className="col m3 s6"><a href="https://github.com/minhanh29" target="_blank">Github</a></li>
						<li className="col m3 s6"><a href="https://www.facebook.com/minhanh.cszone" target="_blank">Facebook</a></li>
						<li className="col m3 s6"><a href="https://www.linkedin.com/in/minh-anh-nguy%E1%BB%85n-b80aa0152/" target="_blank">Linkedin</a></li>
						<li className="col m3 s6"><a href="https://www.youtube.com/channel/UCyafRmRiKO2bOtm0MXQ_LOA" target="_blank">Youtube</a></li>
					</ul>

					<p className="footer-info">©{year} Nguyen Minh Anh - CS Zone</p>
				</div>
			</footer>
		)
	}
}

export default Footer
