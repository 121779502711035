import React, { useEffect } from 'react';
import { Slide } from 'react-awesome-reveal';
import { AiFillGithub } from "react-icons/ai"
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebaseConfig"

import Recommend from '../../layout/Recommend'
import Footer from '../../layout/Footer'

import './omp.css'
import omp_dashboard from '../../../images/omp_dashboard.png'
import omp_friends from '../../../images/omp_friends.png'
import google_badge from '../../../images/google-play-badge.png'


const Omp = () => {

	useEffect(() => {
		logEvent(analytics, 'screen_view', {
			firebase_screen: "OMP_Project",
			firebase_screen_class: "OMP_Project"
		});
	}, [])

	return (
		<div className="overflow-x-hidden">
			<div className="omp-wallpaper overflow-x-hidden">
				<div className="row">
					<div className="col s12 m12 l12">
					<Slide direction='left' duration={1500} triggerOnce={true} className="full-width">
						<div className="omp-title">
							Optimal Meeting Point
						</div>
					</Slide>
					<Slide direction='left' duration={1500} triggerOnce={true} className="full-width">
						<div className="omp-subtitle">
							Find optimal locations for hanging out
						</div>
					</Slide>
					</div>
				</div>

				<Slide direction='up' duration={1500} triggerOnce={true} className="full-width omp-descript">
					<div className="row">
					<div className="col s12 m4 center">
						<span className="omp-em">Role</span> Project Leader
					</div>
					<div className="col s12 m4 center">
						<span className="omp-em">Context</span> Application
					</div>
					<div className="col s12 m4 center">
						<span className="omp-em">Period</span> Winter 2022
					</div>
					</div>
				</Slide>
			</div>

			<div className="omp-intro">
				<div className="omp-intro-container">
					<div className="omp-intro-title"
						data-aos="flip-up"
						data-aos-delay="200"
					>
						Introduction
					</div>
					<div className="omp-intro-content"
						data-aos="fade-up"
						data-aos-delay="200"
					>
						Optimal Meeting Point is a mobile application which allows users to find the most suitable meeting points of a group for users by providing them features to organize groups, send invitations, accept or reject any invitation, and insert their location.
					</div>
					<div className="center"
						data-aos="flip-right"
						data-aos-delay="300"
						onClick={() => logEvent(analytics, "omp_download_google_play")}
					>
						<a href='https://play.google.com/store/apps/details?id=com.omprmit.mobile' target='_blank' className="pointer">
							<img className="google-badge" alt='Get it on Google Play' src={google_badge} />
						</a>
					</div>
				</div>
			</div>

			<div className='omp-title-container'>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					INFORMATION & ANALYSIS
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Project Details
				</div>
			</div>

			<div className="row omp-section-container">
				<div className="col l5 m12"
					data-aos="fade-down"
					data-aos-delay="200"
				>
					<div className="section-slogan">
						Save users&apos; searching and transporting time
					</div>
					<div className="section-slogan-divider-left"></div>
					<div className="section-content">
						The system will help users find their desired locations faster and give one of the most <span className="omp-em-2">optimal meeting locations</span> which minimize the users’ traveling time.<br/><br/>
						OMP provides several meeting points within a certain radius from the center calculated from users’
locations.
					</div>
				</div>
				<div className="col l7 m12 center"
					data-aos="fade-up"
					data-aos-delay="200"
				>
					<img className="omp-section-img" src={omp_dashboard} alt="YouHand Interface" />
				</div>
			</div>

			<div className="row omp-section-container">
				<div className="col l7 m12 center hide-on-med-and-down"
					data-aos="fade-up"
					data-aos-delay="200"
				>
					<img className="omp-section-img max-width-400" src={omp_friends} alt="YouHand Interface" />
				</div>

				<div className="col l5 m12"
					data-aos="fade-down"
					data-aos-delay="200"
				>
					<div className="section-slogan text-right">
						Strengthen the bonds among users
					</div>
					<div className="section-slogan-divider">
						<div className="section-slogan-divider-right"></div>
					</div>
					<div className="section-content">
						Users can send <span className="omp-em-2">friend & group requests</span> to connect with their friend. They can view, add, delete groups which will include the friends that they want to hang out with.<br/><br/>
						The ease of finding the meeting location will encourage friends to hang out frequently, which <span className="omp-em-2">strengthens the bonds</span> among friends.
					</div>
				</div>

				<div className="col l7 m12 center hide-on-large-only margin-top-40"
					data-aos="fade-up"
					data-aos-delay="200"
				>
					<img className="omp-section-img max-width-400" src={omp_friends} alt="YouHand Interface" />
				</div>
			</div>

			<div className='omp-title-container no-margin-top'>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					Reference
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Source Code
				</div>
			</div>

			<div className="omp-demo-container"
					data-aos="fade-down"
					data-aos-delay="200"
			>
				<div
					data-aos="flip-right"
					data-aos-delay="300"
					onClick={() => logEvent(analytics, "omp_open_code")}
				>
					<a className="omp-intro-web text-pulse-btn" href="https://github.com/minhanh29/optimal-meeting-point" target="_blank">
						View in GitHub &nbsp;&nbsp;<AiFillGithub size={25}/>
					</a>
				</div>
			</div><br/>

			<Recommend subtitle="NEXT PROJECT" title="Sudoku Solver" to="/sudoku" />
			<Footer />
		</div>
	)
}

export default Omp
