import React, { Component } from 'react';

// create scramble appearing effect
class TextScramble extends Component {
	constructor(props) {
		super()
		this.state = {
			chars: '!-_[]{}—=+*^__',
			// chars: '░█',
			newText: props.text,
			oldText: props.oldText,
			showText: props.oldText,
			frame: 0,
			queue: [],
			hasSetText: false,
			delay: props.delay,
		}
	}

	componentDidMount() {
		if (this.state.hasSetText) {
			return
		}

		setTimeout(this.setText, this.state.delay)
		this.setState({ hasSetText: true })
	}

	setText = () => {
		const oldText = this.state.oldText
		const newText = this.state.newText
		console.log(oldText, newText)
		const length = Math.max(oldText.length, newText.length)
		const promise = new Promise((resolve) => this.resolve = resolve)
		for (let i = 0; i < length; i++) {
			const from = oldText[i] || ''
			const to = newText[i] || ''
			const start = Math.floor(Math.random() * 40)
			const end = start + Math.floor(Math.random() * 40)
			this.state.queue.push({ from, to, start, end })
		}
		cancelAnimationFrame(this.frameRequest)
		this.setState({ frame: 0, hasSetText: true })
		this.update()
		return promise
	}

	update = () => {
		let output = ''
		let complete = 0
		for (let i = 0, n = this.state.queue.length; i < n; i++) {
			let { from, to, start, end, char } = this.state.queue[i]
			if (this.state.frame >= end) {
				complete++
				output += to
			} else if (this.state.frame >= start) {
				if (!char || Math.random() < 0.28) {
					char = this.randomChar()
					this.state.queue[i].char = char
				}
				// output += `<span class="dud">${char}</span>`
				output += char
			} else {
				output += from
			}
		}
		this.setState({ showText: output })
		if (complete === this.state.queue.length) {
			this.resolve()
		} else {
			this.frameRequest = requestAnimationFrame(this.update)
			this.setState({frame: this.state.frame+1})
		}
	}

	randomChar = () => {
		return this.state.chars[Math.floor(Math.random() * this.state.chars.length)]
	}

	render() {
		return (
			<p className={this.props.className}>{this.state.showText}</p>
		)
	}
}

export default TextScramble
