import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import { MdCloudUpload } from 'react-icons/md';
import "./textswap.css"

const MyDropzone = ({ callback }) => {
	const onDrop = useCallback(acceptedFiles => {
	  if (acceptedFiles.length > 0) {
		  const file = acceptedFiles[0]
		  callback(file)
	  } else {
		  window.alert("Invalid file type.")
	  }
	}, [])

	const {acceptedFiles, getRootProps, getInputProps, isDragActive} = useDropzone({
		onDrop,
		accept: "image/png, image/gif, image/jpeg"
	})

  return (
	<div>
		<div {...getRootProps()} className={"img-dropzone-container grey " + (isDragActive ? "darken-1" : "darken-3")}>
			<input {...getInputProps()} />
			<i className="material-icons white-text img-upload-icon"><MdCloudUpload /></i>
			<p className="white-text"><span className="bold">Choose an image</span> or drag it here.</p>
		</div>
	</div>
  )
}

export default MyDropzone

