import React, { Component } from 'react';

class ConsoleText extends Component {
	constructor(props) {
		super()
		this.state = {
			word: props.word,
			showText: props.word[0],
			letterCount: 1,
			hasUpdate: false,
			delay: props.delay,
		}
	}

	componentDidMount() {
		if (this.state.hasUpdate) {
			return
		}

		setTimeout(this.startDisplay, this.state.delay)
		this.setState({ hasUpdate: true })
	}

	startDisplay = () => {
		this.myLoop = setInterval(this.update, 100)
	}

	update = () => {
		if (this.state.letterCount === 0) {
			this.setState({ waiting: true })
			this.setState({ showText: this.state.word.substring(0, this.state.letterCount) + '_'})

		} else if (this.state.letterCount === this.state.word.length + 1) {
			clearInterval(this.myLoop)
		} else {
			let word = this.state.word.substring(0, this.state.letterCount)
			if (this.state.letterCount % 2 == 0 && this.state.letterCount != this.state.word.length)
				word += '_'
			this.setState({ showText: word })
			this.setState({ letterCount: this.state.letterCount + 1 })
		}

		if (this.state.letterCount === this.state.word.length + 1) {
		}
	}

	render() {
		return (
			<div className={this.props.className}>
				{this.state.showText}
			</div>

		)
	}
}

export default ConsoleText
