import React, { useEffect, useState } from 'react'
import ConsoleText from '../utils/ConsoleText'
import M from "materialize-css"
import emailjs from "@emailjs/browser"
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebaseConfig"
import './contact.css'

const Contact = () => {
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [subject, setSubject] = useState("")
	const [message, setMessage] = useState("")
	const [sending, setSending] = useState(false)

	useEffect(() => {
		logEvent(analytics, 'screen_view', {
			firebase_screen: "Contact",
			firebase_screen_class: "Contact"
		});
	}, [])

	const handleSubmit = (e) => {
		e.preventDefault();
		setSending(true)
		let myItem = { name, email, subject, message }

		emailjs.send('service_wjud1t8', 'personal_web', myItem, "user_L6BKHC757MIuv8lKLbgsm")
			.then(() => {
				setSending(false)
				M.toast({html: "Your message has been received. I will get back to you soon.", classes: 'green white-text'});
			}, (e) => {
				setSending(false)
				M.toast({html: "Error! Your connection is unstable.", classes: 'red white-text'});
		});
	}

	return(
		<div className="contact-container">
			<div className='contact-bg'></div>
			<div className="container form-container">
				<div className="row">
					<div className="contact-info-container col s12 m12 l6">
						<div
							data-aos="fade-right"
							data-aos-delay={800}
						>
							<ConsoleText
								word="Get In Touch"
								className="contact-title"
								delay={1000}
							/>
						</div>

						<div
							data-aos="fade-right"
							data-aos-delay={1000}
						>
							<p className="contact-subtitle">If you are urgent, call me instead</p>
							<p className="white-text contact-subinfo"
							>
							<i className="material-icons prefix cyan-text text-accent-3">email</i>  minhanhng.sofl@gmail.com<br/>	<i className="material-icons prefix cyan-text text-accent-3">phone</i>  0325965272
							</p>
						</div>
					</div>

					<div className="contact-form-container s12 col m12 l6"
							data-aos="fade-up"
							data-aos-delay={800}
					>
						<form onSubmit={handleSubmit} id="contact-form">
							<h5 className="contact-form-title">Contact Me</h5>
							<p className="contact-form-subtitle">Please fill out the form below</p>

							<div className="input-field my-input-field">
								<i className="material-icons prefix orange-text text-lighten-1">account_circle</i>
								<label htmlFor="name">Your Name <span className="red-text">*</span></label>
								<input type="text" id="name" name="name" className="validate" onChange={e => setName(e.target.value)} required/>
							</div>

							<div className="input-field my-input-field">
								<i className="material-icons prefix orange-text text-lighten-1">email</i>
								<label htmlFor="email">Email <span className="red-text">*</span></label>
								<input type="email" id="email" name="email" className="validate" onChange={e => setEmail(e.target.value)} required/>
							</div>

							<div className="input-field my-input-field">
								<i className="material-icons prefix orange-text text-lighten-1">subject</i>
								<label htmlFor="subject">Subject <span className="red-text">*</span></label>
								<input type="text" id="subject" name="subject" className="validate" onChange={e => setSubject(e.target.value)} required/>
							</div>

							<div className="input-field my-input-field">
								<i className="material-icons prefix orange-text text-lighten-1">mode_comment</i>
								<label htmlFor="message">Message <span className="red-text">*</span></label>
								<textarea id="message" name="message" className="materialize-textarea" onChange={e => setMessage(e.target.value)} required/>
							</div>

							<div className="input-field">
								<button type="submit" className={"btn orange darken-2 z-depth-1" + (sending ? " disabled" : "")}>Send</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Contact
