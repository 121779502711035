import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	fontList: [],
	isFontLoaded: true,
}

export const fontSlice = createSlice({
	name: "font",
	initialState,
	reducers: {
		addFont: (state, action) => {
			const data = action.payload
			if (data.length === 0)
				return

			let fontSet = new Set(state.fontList.map(item => item.fontId))
			let newFontList = [...state.fontList]
			for (let i = 0; i < data.length; i++) {
				console.log("Adding", data)
				let item = data[i]
				if (!fontSet.has(item.fontId)) {
					fontSet.add(item.fontId)
					newFontList.push({
						fontId: item.fontId,
						fontName: item.fontName,
					})
				}
			}
			state.fontList = newFontList
		},
		setLoadFontStatus: (state, action) => {
			state.isFontLoaded = action.payload
		},
	}
});

export const selectFont = state => state.font

export const { addFont, setLoadFontStatus } = fontSlice.actions

export default fontSlice.reducer

