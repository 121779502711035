import React, { useEffect, useState, useRef } from 'react';
import axios from "axios"
import { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom'
import { HiOutlineDownload } from "react-icons/hi"
import { MdUndo } from "react-icons/md"
import { RxHand, RxEraser } from "react-icons/rx"
import { FiMousePointer } from "react-icons/fi"
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai"
import M from "materialize-css"
import { useSelector, useDispatch } from 'react-redux'
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebaseConfig"

import { selectFont, addFont } from "../../../redux/reducers/fontSlice"
import { removeFromHistory, resetTextList, removeText } from "../../../redux/reducers/textswapSlice"
import {
	setTool,
	selectTool,
	TOOL_SELECT_RECTANGLE,
	TOOL_SELECT_PERSPECTIVE,
	TOOL_ERASER,
	TOOL_HAND,
	TOOL_ZOOM_IN,
	TOOL_ZOOM_OUT,
	TOOL_TRANSFORM
} from "../../../redux/reducers/toolSlice"
import { downloadURI } from "./Utils"

import MyCanvas from "./MyCanvas"
import Inspector from "./Inspector"

import logo from '../../../images/logo_small.png'
import './textswap.css'


export const API_ENDPOINT = "https://text-swap-api-dz5uwcptoq-de.a.run.app"

const GlobalStyle = createGlobalStyle`
  body {
    @font-face {
		font-family: '${props => props.fontName || "Arial"}';
		src: url('${API_ENDPOINT}/font/${props => props.fontId}') format('woff');
		font-style: normal;
		font-display: swap;
    };
  }
`

const TextSwapEditor = () => {
	const { toolId } = useSelector(selectTool)
	const { fontList } = useSelector(selectFont)
	const dispatch = useDispatch()
	const canvas = useRef()
	const [ping, setPing] = useState(false)

	const [layer, setLayerNode] = useState(null)
	const [mainImg, setMainImg] = useState(null)
	const [inpaintingImages, setInpaintingImages] = useState([])

	const [translateText, setTranslateText] = useState(true)

	useEffect(() => {
		logEvent(analytics, 'screen_view', {
			firebase_screen: "ImageTextTool",
			firebase_screen_class: "ImageTextTool"
		});

		let elems = document.querySelectorAll('select');
		M.FormSelect.init(elems, {});

		// ping the api
		pingAPI()
	}, [])

	const pingAPI = async () => {
		try {
			await axios.get(API_ENDPOINT + "/ping")
			setPing(true)
		} catch (e) {
			M.toast({html: e.message, classes: 'white red-text'});
		}
	}

	const sendBlobToAPI = (blob, perspectivePoints) => {
		return new Promise(function(resolve, reject) {
			_sendBlobToAPI(blob, perspectivePoints).then(resolve, reject);
			setTimeout(reject, 120000);
		});
	}

	const _sendBlobToAPI = async (blob, perspectivePoints) => {
		logEvent(analytics, 'textswap_api_text');

		// send image to the server
		let formData = new FormData();
		let fileName = "ts_" + (new Date()).getTime() + ".png"
		let file = new File([blob], fileName);
		formData.append('file', file, fileName);
		perspectivePoints.forEach(val => formData.append("perspectivePoints", parseInt(val)))
		formData.append('translate', translateText ? "true" : "false");

		const response = await axios.post(API_ENDPOINT + "/edit",
			formData, {
				headers: {
					'Content-Type': `multipart/form-data`,
				},
			})

		const data = response.data

		if (!data.success) {
			M.toast({html: data.message, classes: 'white red-text'});
			return null
		}

		dispatch(addFont(data.info.map(item => ({
			fontId: item.fontId,
			fontName: item.fontName,
		}))))

		return data
	}

	const sendInpaintingBlob = (imgBlob, maskBlob, padList) => {
		logEvent(analytics, 'textswap_api_inpaint');
		return new Promise(function(resolve, reject) {
			_sendInpainingBlob(imgBlob, maskBlob, padList).then(resolve, reject);
			setTimeout(reject, 120000);
		});
	}

	const _sendInpainingBlob = async (imgBlob, maskBlob, padList) => {
		// send image to the server
		let formData = new FormData();
		let fileName1 = "img_" + (new Date()).getTime() + ".png"
		let file1 = new File([imgBlob], fileName1);
		formData.append('imgFile', file1, fileName1);

		let fileName2 = "mask_" + (new Date()).getTime() + ".png"
		let file2 = new File([maskBlob], fileName2);
		formData.append('maskFile', file2, fileName2);

		padList.forEach(val => formData.append("padList", parseInt(val)))

		const response = await axios.post(API_ENDPOINT + "/inpaint",
			formData, {
				headers: {
					'Content-Type': `multipart/form-data`,
				},
				responseType: "blob"
			})

		return response.data
	}

	const handleUndo = () => {
		if (inpaintingImages.length === 0)
			return

		const historyOrder = inpaintingImages[inpaintingImages.length-1].historyOrder
		setInpaintingImages(inpaintingImages.filter(item => item.historyOrder !== historyOrder))
		dispatch(removeFromHistory(historyOrder))
	}

	const handleNewSession = () => {
		logEvent(analytics, 'textswap_new_session');
		setMainImg(null)
		setInpaintingImages([])
		dispatch(resetTextList())
	}

	const handleDownload = () => {
		logEvent(analytics, 'textswap_download');

		if (!layer)
			return
		let dataURL = layer.toDataURL({ pixelRatio: window.devicePixelRatio, imageSmoothingEnabled: false });
		downloadURI(dataURL, 'edited_image.png');
	}

	const handleKeyDown = (e) => {
		// backspace
		if (e.keyCode === 8)   {
			e.preventDefault()
			dispatch(removeText())
		}
	}

	if (window.innerWidth < 650) {
		return (<div className="page-container">
			{fontList.map((item, index) => (
				<GlobalStyle key={index} {...item} />
			))}
			<div className="header-bar">
				<div className="textswap-header-container"
					data-aos="fade-down"
					data-aos-delay="1500"
				>
					<div className="textswap-logo-container">
						<Link to="/">
							<img className="textswap-logo-img" src={logo} />
						</Link>
						<div className="textswap-title hide-on-med-and-down">Image Text Editor</div>
						<div className="textswap-title hide-on-large-only show-on-medium">IT Editor</div>
					</div>
				</div>
			</div>

			<div className="textswap-error-text-container">
				This application is only available on desktop or iPad
				<Link to="/" className="notfound-intro-web pulse-btn" >
					Go back home
				</Link>
			</div>
		</div>)
	}

	return (
		<div className="page-container">
			{fontList.map((item, index) => (
				<GlobalStyle key={index} {...item} />
			))}
			<div className="header-bar">
				<div className="textswap-header-container"
					data-aos="fade-down"
					data-aos-delay="1500"
				>
					<div className="textswap-logo-container">
						<Link to="/">
							<img className="textswap-logo-img" src={logo} />
						</Link>
						<div className="textswap-title hide-on-med-and-down">Image Text Editor</div>
						<div className="textswap-title hide-on-large-only hide-on-small-only show-on-medium">IT Editor</div>
					</div>

					<div className="right textswap-header-tool-container">
						<div className="textswap-btn-flat" onClick={handleUndo}><i className="material-icons left"><MdUndo size={27}/></i></div>
						<div className="textswap-btn" onClick={handleNewSession}><i className="material-icons left">add</i>New Session</div>
						<div className="textswap-btn-solid" onClick={handleDownload}><i className="material-icons left"><HiOutlineDownload /></i>Download</div>
					</div>
				</div>
			</div>

			<div className="editor-container">
				<div className="tool-container">
					<div
						className={"tool-btn " + (toolId === TOOL_SELECT_RECTANGLE || toolId === TOOL_SELECT_PERSPECTIVE ? "grey darken-3 tool-btn-border" : "")}
						onClick={() => dispatch(setTool(TOOL_SELECT_RECTANGLE))}
					>
						<i className="material-icons grey-text text-lighten-3 tool-icon"><FiMousePointer /></i>
					</div>

					<div
						className={"tool-btn " + (toolId === TOOL_TRANSFORM ? "grey darken-3 tool-btn-border" : "")}
						onClick={() => dispatch(setTool(TOOL_TRANSFORM))}
					>
						<i className="material-icons grey-text text-lighten-3 tool-icon">format_shapes</i>
					</div>

					<div
						className={"tool-btn " + (toolId === TOOL_ERASER ? "grey darken-3 tool-btn-border" : "")}
						onClick={() => dispatch(setTool(TOOL_ERASER))}
					>
						<i className="material-icons grey-text text-lighten-3 tool-icon"><RxEraser /></i>
					</div>

					<div
						className={"tool-btn " + (toolId === TOOL_HAND ? "grey darken-3 tool-btn-border" : "")}
						onClick={() => dispatch(setTool(TOOL_HAND))}
					>
						<i className="material-icons grey-text text-lighten-3 tool-icon"><RxHand size={20}/></i>
					</div>

					<div
						className={"tool-btn " + (toolId === TOOL_ZOOM_IN ? "grey darken-3 tool-btn-border" : "")}
						onClick={() => dispatch(setTool(TOOL_ZOOM_IN))}
					>
						<i className="material-icons grey-text text-lighten-3 tool-icon"><AiOutlineZoomIn /></i>
					</div>

					<div
						className={"tool-btn " + (toolId === TOOL_ZOOM_OUT ? "grey darken-3 tool-btn-border" : "")}
						onClick={() => dispatch(setTool(TOOL_ZOOM_OUT))}
					>
						<i className="material-icons grey-text text-lighten-3 tool-icon"><AiOutlineZoomOut /></i>
					</div>
				</div>

				<div className="canvas-container" ref={canvas} tabIndex={1} onKeyDown={handleKeyDown}>
					{ping ?
					<MyCanvas
						setLayerNode={setLayerNode}
						sendBlobToAPI={sendBlobToAPI}
						sendInpaitingBlob={sendInpaintingBlob}
						mainImg={mainImg}
						setMainImg={setMainImg}
						inpaintingImages={inpaintingImages}
						setInpaintingImages={setInpaintingImages}
						parentWidth={canvas.current ? canvas.current.offsetWidth : window.innerWidth}
						parentHeight={canvas.current ? canvas.current.offsetHeight : window.innerHeight}
					/>
					:
					<div className="my-spinner-container center">
						<div className="preloader-wrapper big active">
						<div className="spinner-layer spinner-yellow-only">
							<div className="circle-clipper left">
								<div className="circle"></div>
							</div>
							<div className="gap-patch">
								<div className="circle"></div>
							</div>
							<div className="circle-clipper right">
								<div className="circle"></div>
							</div>
						</div>
						</div>
						<h6 className="grey-text text-lighten-4 center">Setting up the server<span className="loading-dot"></span></h6>
					</div>}
				</div>

				<Inspector
					translateText={translateText}
					setTranslateText={setTranslateText}
				/>
			</div>
		</div>
	)
}

export default TextSwapEditor
