import React, { useState, useEffect } from 'react'

import "./chatbot.css"
import chatbot_icon from "../../images/chatbot.png"

const BotResponse = ({ message }) => {
	return (
		<div className="bot-response-container">
			<div className="bot-response-ava">
				<img src={chatbot_icon} alt="ava" />
			</div>
			<div className="bot-response-message">
				{message}
			</div>
		</div>
	)
}

export default BotResponse

