// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDtueWsnsei4LjA2cnK_d1xqkJnYWWYVbk",
  authDomain: "minhanh-web.firebaseapp.com",
  databaseURL: "https://minhanh-web-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "minhanh-web",
  storageBucket: "minhanh-web.appspot.com",
  messagingSenderId: "1096833513201",
  appId: "1:1096833513201:web:472b55621eb50bff215b50",
  measurementId: "G-ECJMEDPS50"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
