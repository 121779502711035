import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Circle, Line, Rect, Group, Image as KonvaImage} from 'react-konva';
import { useSelector, useDispatch } from 'react-redux'
import Konva from "konva"
import M from "materialize-css"

import TextTransformer from "./TextTransformer"
import TextPerspective from "./TextPerspective"
import ImageDropzone from "./ImageDropzone"
import { addTexts, removeText, updateAttribute, setSelectedTextId, selectTextswap } from "../../../redux/reducers/textswapSlice"
import {
	setIsProcessing,
	setTool,
	selectTool,
	TOOL_SELECT_RECTANGLE,
	TOOL_SELECT_PERSPECTIVE,
	TOOL_ERASER,
	TOOL_HAND,
	TOOL_ZOOM_IN,
	TOOL_ZOOM_OUT,
	TOOL_TRANSFORM
} from "../../../redux/reducers/toolSlice"

import './textswap.css'

const MAX_WIDTH = 2048;

const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}


const MyCanvas = ({ setLayerNode, sendBlobToAPI, sendInpaitingBlob, mainImg, setMainImg, parentWidth, parentHeight, inpaintingImages, setInpaintingImages }) => {

	const stage = useRef()
	const { toolId, isProcessing, eraserStrokeWidth } = useSelector(selectTool)
	const { textList, selectedTextId } = useSelector(selectTextswap)
	const dispatch = useDispatch()

	// canvas size
	const [canvasWidth, setCanvasWidth] = useState(parentWidth)
	const [canvasHeight, setCanvasHeight] = useState(parentHeight)

	const [imgGroupNode, setImgGroupNode] = useState(null)
	const [imgNode, setImgNode] = useState(null)

	// rectangle selection mouse event
	const [mouseDragging, setMouseDragging] = useState(false)
	const [dragBoxPivot, setDragBoxPivot] = useState({ x: 0, y: 0 })
	const [dragBox, setDragBox] = useState({ x: 0, y: 0, w: 0, h: 0 })

	// 4 point mouse vvent
	const [perspectiveBox, setPerspectiveBox] = useState([])
	const perspectivePointX = perspectiveBox.filter((val, i) => i % 2 === 0)
	const perspectivePointY = perspectiveBox.filter((val, i) => i % 2 !== 0)

	// erase tool
	const [erasePoints, setErasePoints] = useState([])
	const [isErasing, setIsErasing] = useState(false)
	const [eraseNode, setEraseNode] = useState(null)

	// event timeout
	const [enableMouse, setEnableMouse] = useState(true)

	useEffect(() => {
		if (toolId !== TOOL_SELECT_RECTANGLE) {
			setDragBox({ x: 0, y: 0, w: 0, h: 0 })
			setDragBoxPivot({ x: 0, y: 0 })
			setMouseDragging(false)
		}

		if (toolId !== TOOL_SELECT_PERSPECTIVE) {
			setPerspectiveBox([])
		}

		if (toolId !== TOOL_TRANSFORM && selectedTextId !== -1) {
			dispatch(setSelectedTextId(-1))
		}
	}, [toolId])

	const createImageFromBlob = (blob, callback) => {
		var URL = window.webkitURL || window.URL;
		const url = URL.createObjectURL(blob);
		var img = new Image();
		img.src = url;
		img.onload = () => callback(img)
	}

	const stageTouch = (e) => {
		if (!enableMouse || !imgNode || isProcessing)
			return

		if (toolId === TOOL_HAND) {
			e.target.getStage().container().style.cursor = "grabbing";
			return
		}

		else if (toolId === TOOL_ZOOM_IN) {
			handleZoom(true, 1.2)
			return
		}

		else if (toolId === TOOL_ZOOM_OUT) {
			handleZoom(false, 1.2)
			return
		}

		else if (toolId === TOOL_SELECT_RECTANGLE) {
			roiStart()
		}

		else if (toolId === TOOL_SELECT_PERSPECTIVE) {
			perspectiveSelecttionStart()
		}

		else if (toolId === TOOL_ERASER) {
			eraseStart()
		}

		// deselect when clicked on empty area
		const clickedOnEmpty = e.target === e.target.getStage() || e.target === imgNode;
		console.log(e.target)
		let clickOnText = e.target instanceof Konva.Rect
		for (let i = 0; i < textList.length; i++) {
			if ("text_" + textList[i].id === e.target.id()) {
				clickOnText = true;
				break;
			}
		}

		if (clickedOnEmpty || !clickOnText) {
			dispatch(setSelectedTextId(null));
		}
	}

	const stageMove = (e) => {
		if (toolId === TOOL_SELECT_RECTANGLE) {
			roiProcess()
		}

		else if (toolId === TOOL_ERASER) {
			eraseMove()
		}
	}

	const stageLeave = (e) => {
		if (!imgNode)
			return

		e.evt.preventDefault();
		if (toolId === TOOL_HAND) {
			e.target.getStage().container().style.cursor = "grab";
		}

		else if (toolId === TOOL_SELECT_RECTANGLE) {
			roiEnd()
		}

		else if (toolId === TOOL_ERASER) {
			eraseEnd()
		}
	}

	const getPointerPosition = () => {
		const pos = imgGroupNode.getRelativePointerPosition()
		let posX = Math.max(Math.min(imgNode.x() + imgNode.width(), pos.x), imgNode.x())
		let posY = Math.max(Math.min(imgNode.y() + imgNode.height(), pos.y), imgNode.y())
		return { x: posX, y: posY }
	}

	const eraseStart = () => {
		setIsErasing(true)
		const pos = getPointerPosition()
		setErasePoints([pos.x, pos.y])
	}

	const eraseMove = () => {
		if (!isErasing)
			return

		const pos = getPointerPosition()
		const newList = [
			...erasePoints,
			pos.x, pos.y
		]
		setErasePoints(newList)
	}

	const eraseEnd = () => {
		if (!eraseNode) {
			return
		}

		let xList = erasePoints.filter((val, i) => i % 2 === 0)
		let yList = erasePoints.filter((val, i) => i % 2 !== 0)
		let strokePadding = eraserStrokeWidth / 2
		let x1 = Math.min(...xList) - strokePadding
		let x2 = Math.max(...xList) + strokePadding
		let y1 = Math.min(...yList) - strokePadding
		let y2 = Math.max(...yList) + strokePadding

		x1 = Math.max(Math.min(imgNode.x() + imgNode.width(), x1), imgNode.x())
		x2 = Math.max(Math.min(imgNode.x() + imgNode.width(), x2), imgNode.x())
		y1 = Math.max(Math.min(imgNode.y() + imgNode.height(), y1), imgNode.y())
		y2 = Math.max(Math.min(imgNode.y() + imgNode.height(), y2), imgNode.y())

		// add padding
		let paddingWidth = Math.abs(x2 - x1) * 0.2
		let paddingHeight = Math.abs(y2 - y1) * 0.2

		let px1 = x1 - paddingWidth
		let px2 = x2 + paddingWidth
		let py1 = y1 - paddingHeight
		let py2 = y2 + paddingHeight

		px1 = Math.max(Math.min(imgNode.x() + imgNode.width(), px1), imgNode.x())
		px2 = Math.max(Math.min(imgNode.x() + imgNode.width(), px2), imgNode.x())
		py1 = Math.max(Math.min(imgNode.y() + imgNode.height(), py1), imgNode.y())
		py2 = Math.max(Math.min(imgNode.y() + imgNode.height(), py2), imgNode.y())
		console.log(imgNode.y(), py1, y1)

		let padX1 = parseInt(Math.abs(px1 - x1))
		let padX2 = parseInt(Math.abs(px2 - x2))
		let padY1 = parseInt(Math.abs(py1 - y1))
		let padY2 = parseInt(Math.abs(py2 - y2))

		dispatch(setIsProcessing(true))
		setEnableMouse(false)
		eraseNode.toBlob({
			callback: blob => {
				processErase(blob, px1, py1, Math.abs(px2 - px1), Math.abs(py2 - py1), [padX1, padY1, padX2, padY2], () => {
					setErasePoints([])
					setEraseNode(null)
					dispatch(setIsProcessing(false))
					setEnableMouse(true)
				})
			}
		})

		setIsErasing(false)
	}

	const perspectiveSelecttionStart = () => {
		if (!stage.current || !imgNode)
			return

		if (perspectiveBox.length >= 8) {
			setPerspectiveBox([])
			return
		}

		const pos = getPointerPosition()
		const newList = [
			...perspectiveBox,
			pos.x, pos.y,
		]
		setPerspectiveBox(newList)
		if (newList.length === 8) {
			// find the bbox
			let xList = newList.filter((val, i) => i % 2 === 0)
			let yList = newList.filter((val, i) => i % 2 !== 0)
			let x1 = Math.min(...xList)
			let x2 = Math.max(...xList)
			let y1 = Math.min(...yList)
			let y2 = Math.max(...yList)

			// process img
			dispatch(setIsProcessing(true))
			setEnableMouse(false)
			processImg(x1, y1, Math.abs(x2 - x1), Math.abs(y2 - y1), newList.map((val, i) => {
				// convert the perspective point relative to the bounding box
				if (i % 2 == 0) {
					return val - x1
				}
				return val - y1
			}), (textId) => {
				setPerspectiveBox([])
				dispatch(setIsProcessing(false))
				setEnableMouse(true)
				if (textId) {
					dispatch(setTool(TOOL_TRANSFORM))
					dispatch(setSelectedTextId(textId))
				}
			})
		} else {
			setEnableMouse(false)
			setTimeout(() => setEnableMouse(true), 100)
		}
	}

	const roiStart = () => {
		if (toolId !== TOOL_SELECT_RECTANGLE || !stage.current || !imgNode)
			return

		const pos = getPointerPosition()
		setDragBoxPivot({ x: pos.x, y: pos.y })
		setMouseDragging(true)
	}

	const roiProcess = () => {
		if (!mouseDragging || toolId !== TOOL_SELECT_RECTANGLE || !stage.current || !imgNode)
			return

		const pos = getPointerPosition()

		let w = Math.abs(dragBoxPivot.x - pos.x);
		let h = Math.abs(dragBoxPivot.y - pos.y);
		let x = dragBoxPivot.x
		let y = dragBoxPivot.y

		// cursor is on the left of the pivot point
		if (pos.x < dragBoxPivot.x) {
			x = pos.x
		}

		// cursor is above the pivot point
		if (pos.y < dragBoxPivot.y) {
			y = pos.y
		}

		setDragBox({ x, y, w, h })
	}

	const roiEnd = () => {
		if (toolId !== TOOL_SELECT_RECTANGLE || !mouseDragging || !imgNode)
			return

		setMouseDragging(false)

		// crop the image
		dispatch(setIsProcessing(true))
		setEnableMouse(false)
		processImg(dragBox.x, dragBox.y, dragBox.w, dragBox.h, [], (textId) => {
			setDragBox({ x: 0, y: 0, w: 0, h: 0 })
			setDragBoxPivot({ x: 0, y: 0 })
			dispatch(setIsProcessing(false))
			setEnableMouse(true)
			if (textId) {
				dispatch(setTool(TOOL_TRANSFORM))
				dispatch(setSelectedTextId(textId))
			}
		})
	}

	const processImg = (x, y, w, h, perspectivePointList, callback) => {
		console.log("w", w, h)
		if (w < 20 || h < 20) {
			console.log("calllll")
			M.toast({html: "Size too small. Width and height must be greater than 20.", classes: 'white black-text'});
			callback()
			return
		}

		let isPerspective = perspectivePointList.length === 8
		let tempGroupNode = imgGroupNode.clone()

		tempGroupNode.toImage({
			callback: image => {
				let tempNode = new Konva.Image({
					image: image,
				})

				tempNode.toBlob({
					callback: async blob => {
						try {
							// send image to the server
							const data = await sendBlobToAPI(blob, perspectivePointList)
							if (data === null) {
								callback()
								return
							}

							// convert base64 to blob image
							const byteCharacters = atob(data.image);
							const byteNumbers = new Array(byteCharacters.length);
							for (let i = 0; i < byteCharacters.length; i++) {
								byteNumbers[i] = byteCharacters.charCodeAt(i);
							}
							const byteArray = new Uint8Array(byteNumbers);
							const resultBlob = new Blob([byteArray], {type: "image/png"})
							createImageFromBlob(resultBlob, img => {
								setInpaintingImages([
									...inpaintingImages,
									{
										image: img,
										x: x,
										y: y,
										width: w,
										height: h,
										historyOrder: inpaintingImages.length
									}
								])
							})

							// set the target text
							let newTexts = []
							for (let i = 0; i < data.info.length; i++) {
								let item = data.info[i]
								let bbox = item.bbox
								let textX = x + bbox[0]
								let textY = y + bbox[1]
								let textWidth = Math.abs(bbox[2] - bbox[0] + 1)
								let textHeight = Math.abs(bbox[3] - bbox[1] + 1)

								let oldBbox = item.oldBbox
								newTexts.push(
									{
										id: "" + (textList.length + i),
										fontId: item.fontId,
										textProps: {
											text: item.target_text,
											fontFamily: item.fontName,
											fontSize: item.fontSize - 2,
											lineHeight: 1,
											wrap: "word",
											align: item.center ? "center" : "left",
											fill: rgbToHex(item.color[0], item.color[1], item.color[2]),
										},
										x: textX,
										y: textY,
										width: textWidth,
										height: textHeight,
										perspectivePoints: item.perspectivePoints,
										isPerspective: isPerspective,
										textWidth: Math.abs(oldBbox[2] - oldBbox[0]),
										textHeight: Math.abs(oldBbox[3] - oldBbox[1]),
										foundFontSize: false,
										historyOrder: inpaintingImages.length
									}
								)
							}

							dispatch(addTexts(newTexts))
							let textId = null
							if (newTexts.length > 0) {
								textId = newTexts[newTexts.length-1].id
							}
							callback(textId)
						} catch (e) {
							callback()
							console.log(e)
							M.toast({html: "Unexpected Error", classes: 'white red-text'});
						}
					},
					x: x - imgNode.x(),
					y: y - imgNode.y(),
					width: w,
					height: h,
					imageSmoothingEnabled: false,
				})
			},
			imageSmoothingEnabled: false,
		})
	}

	const processErase = (eraseBlob, x, y, w, h, padList, callback) => {
		if (w < 20 || h < 20) {
			M.toast({html: "Size too small. Width and height must be greater then 20.", classes: 'white black-text'});
			callback()
			return
		}

		let tempGroupNode = imgGroupNode.clone()
		tempGroupNode.toImage({
			callback: image => {
				let tempNode = new Konva.Image({
					image: image,
				})

				tempNode.toBlob({
					callback: async blob => {
						try {
							// send image to the server
							const data = await sendInpaitingBlob(blob, eraseBlob, padList)
							if (data === null) {
								callback()
								return
							}

							createImageFromBlob(data, img => {
								setInpaintingImages([
									...inpaintingImages,
									{
										image: img,
										x: x,
										y: y,
										width: w,
										height: h,
										historyOrder: inpaintingImages.length
									}
								])
							})

							callback()
						} catch (e) {
							callback()
							M.toast({html: "Unexpected Error", classes: 'white red-text'});
						}
					},
					x: x - imgNode.x(),
					y: y - imgNode.y(),
					width: w,
					height: h,
					imageSmoothingEnabled: false,
				})
			},
			imageSmoothingEnabled: false,
		})
	}

	const handleZoom = (willZoomOut, scaleBy = 1.05) => {
		let oldScale = stage.current.scaleX();
		let pointer = stage.current.getPointerPosition();

		let mousePointTo = {
			x: (pointer.x - stage.current.x()) / oldScale,
			y: (pointer.y - stage.current.y()) / oldScale
		};

		let newScale = willZoomOut ? oldScale * scaleBy : oldScale / scaleBy;

		stage.current.scale({ x: newScale, y: newScale });

		let newPos = {
			x: pointer.x - mousePointTo.x * newScale,
			y: pointer.y - mousePointTo.y * newScale
		};
		stage.current.position(newPos);
	};

	const handleWheel = (e) => {
		// stop default scrolling
		e.evt.preventDefault();
		handleZoom(e.evt.deltaY < 0, 1.05)
	}

	const handleCursorShape = (e) => {
		let value = "default"
		if (toolId === TOOL_HAND) {
			value = "grab"
		} else if (toolId === TOOL_ZOOM_IN) {
			value = "zoom-in"
		} else if (toolId === TOOL_ZOOM_OUT) {
			value = "zoom-out"
		}

		e.target.getStage().container().style.cursor = value;
	}

	const handleImgUpload = (img) => {
		if (img.width > MAX_WIDTH) {
			let konvaImg = new Konva.Image({
				image: img
			});
			konvaImg.scaleX(MAX_WIDTH/img.width)
			konvaImg.scaleY(MAX_WIDTH/img.width)

			konvaImg.toBlob({
				callback: blob => {
					createImageFromBlob(blob, resizedImg => setMainImg(resizedImg))
				},
				imageSmoothingEnabled: false,
			})
		} else {
			setMainImg(img)
		}
	}

	if (!mainImg) {
		return <ImageDropzone callback={file => createImageFromBlob(file, img => handleImgUpload(img))}/>
	}

	return (
		<Stage
			ref={stage}
			width={canvasWidth}
			height={canvasHeight}
			onMouseMove={stageMove}
			onTouchMove={stageMove}
			onMouseUp={stageLeave}
			onTouchEnd={stageLeave}
			onMouseDown={stageTouch}
			onTouchStart={stageTouch}
			onMouseOver={handleCursorShape}
			onMouseLeave={(e) => {e.target.getStage().container().style.cursor = "default"}}
			onWheel={handleWheel}
			draggable={toolId === TOOL_HAND}
		>
			<Layer imageSmoothingEnabled={false}>
				<Group ref={node => setLayerNode(node)}>
				<Group
					ref={node => {setImgGroupNode(node)}}
				>
					<KonvaImage
						ref={node => setImgNode(node)}
						image={mainImg}
						x={imgNode ? (canvasWidth - imgNode.width())/2 : 0}
						y={Math.max(50, imgNode & canvasHeight ? (canvasHeight - imgNode.height())/2 : 0)}
					/>
					{inpaintingImages.map((item, index) => (
						<KonvaImage
							key={index}
							{...item}
						/>
					))}
				</Group>
				{textList.map((item, i) => (
					item.isPerspective ?
					<TextPerspective
						key={i}
						shapeProps={item}
						isSelected={item.id === selectedTextId}
						onSelect={() => {
							if (toolId !== TOOL_TRANSFORM) return
							dispatch(setSelectedTextId(item.id));
						}}
						onChange={newAttrs => {
							dispatch(updateAttribute({
								id: item.id,
								value: newAttrs,
							}))
						}}
					/>
					:
					<TextTransformer
						key={i}
						shapeProps={item}
						isSelected={item.id === selectedTextId}
						onSelect={() => {
							if (toolId !== TOOL_TRANSFORM) return
							dispatch(setSelectedTextId(item.id));
						}}
						onChange={newAttrs => {
							dispatch(updateAttribute({
								id: item.id,
								value: newAttrs,
							}))
						}}
					/>
				))}
					</Group>
				{toolId === TOOL_SELECT_RECTANGLE ? <Rect
					x={dragBox.x}
					y={dragBox.y}
					width={dragBox.w}
					height={dragBox.h}
					fill={isProcessing ? "#ef9a9a" : "#90caf9"}
					opacity={0.5}
					strokeWidth={2}
					stroke={isProcessing ? "#d32f2f" : "#1976d2"}
				/> : null}
				{toolId === TOOL_SELECT_PERSPECTIVE ? <Line
					points={perspectiveBox}
					fill={isProcessing ? "#ef9a9a" : "#90caf9"}
					stroke={isProcessing ? "#d32f2f" : "#1976d2"}
					closed={true}
					opacity={0.5}
				/> : null}
				{toolId === TOOL_ERASER ? <Line
					ref={node => setEraseNode(node)}
					points={erasePoints}
					stroke={isProcessing ? "#d32f2f" : "#faa356"}
					lineCap="round"
					lineJoin="round"
					strokeWidth={eraserStrokeWidth}
					closed={false}
					opacity={0.5}
				/> : null}
				{perspectivePointX.map((val, i) => (
					<Circle
						key={i}
						radius={5}
						x={val}
						y={perspectivePointY[i]}
						fill={isProcessing ? "#c62828" : "#1565c0"}
					/>
				))}
			</Layer>
		</Stage>
	)
}

export default MyCanvas

