import { createSlice } from '@reduxjs/toolkit'

export const TOOL_SELECT_RECTANGLE = 0
export const TOOL_SELECT_PERSPECTIVE = 1
export const TOOL_ERASER = 2
export const TOOL_TRANSFORM = 3
export const TOOL_HAND = 4
export const TOOL_ZOOM_IN = 5
export const TOOL_ZOOM_OUT = 6

const descriptionList = [
	{
		name: "Rectangle Selection",
		body: "<div>Draw a rectangle around your text to edit<br/><br/><span class='bold'>Note:</span> Use perspective selection for rotated or warped text</div>",
	},
	{
		name: "Perspective Selection",
		body: "<div>Choose 4 points on the image around your text to edit.<br/><br/><span class='bold'>Note: This tool is a beta version. Only use for perspective text.</span></div>",
	},
	{
		name: "Eraser Tool",
		body: "<div>Erase the text strokes on image</div>",
	},
	{
		name: "Free Transform Tool",
		body: "<div>Click on a detected text and use this inspector to customize it</div>",
	},
	{
		name: "Screen Moving Tool",
		body: "<div>Click and hold to move the canvas</div>",
	},
	{
		name: "Zoom In",
		body: "<div>Click on the canvas to zoom in.<br/><span class='bold'>Shortcut:</span> use middle mouse to scroll up</div>",
	},
	{
		name: "Zoom Out",
		body: "<div>Click on the canvas to zoom out.<br/><span class='bold'>Shortcut:</span> use middle mouse to scroll down</div>",
	}
]

const initialState = {
	toolId: TOOL_SELECT_RECTANGLE,
	isProcessing: false,
	description: descriptionList[TOOL_SELECT_RECTANGLE],
	eraserStrokeWidth: 25,
}

export const toolSlice = createSlice({
	name: "tool",
	initialState,
	reducers: {
		setTool: (state, action) => {
			if (!state.isProcessing) {
				state.toolId = action.payload
				state.description = descriptionList[action.payload]
			}
		},
		setIsProcessing: (state, action) => {
			state.isProcessing = action.payload
		},
		setEraserStrokeWidth: (state, action) => {
			state.eraserStrokeWidth = Math.min(Math.max(action.payload, 0), 100)
		},
	}
});

export const selectTool = state => state.tool

export const { setTool, setIsProcessing, setEraserStrokeWidth } = toolSlice.actions

export default toolSlice.reducer

