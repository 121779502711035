import React, { Component, useEffect } from 'react';
import { Slide, Flip, AttentionSeeker } from 'react-awesome-reveal';
import { Link } from 'react-router-dom'
import { BsBoxArrowUpRight } from "react-icons/bs"
import { AiFillGithub, AiOutlineEdit } from "react-icons/ai"
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebaseConfig"

import Recommend from '../../layout/Recommend'
import Footer from '../../layout/Footer'

import './textswaphome.css'

import opencv_icon from '../../../images/opencv_icon.png'
import pytorch_icon from '../../../images/pytorch_icon.png'
import konva_icon from '../../../images/konva_icon.png'
import fastapi_icon from '../../../images/fastapi_icon.png'

const TextSwapHome = () => {

	useEffect(() => {
		logEvent(analytics, 'screen_view', {
			firebase_screen: "ImageTextProject",
			firebase_screen_class: "ImageTextProject"
		});
	}, [])

	return (
		<div className="overflow-x-hidden">
			<div className="textswaphome-wallpaper overflow-x-hidden">
				<Slide direction='left' duration={1500} triggerOnce={false} className="full-width">
					<div className="textswaphome-home-title">
						Image Text Editor
					</div>
				</Slide>
				<Slide direction='right' duration={1500} triggerOnce={false} className="full-width">
					<div className="textswaphome-subtitle">
						Edit texts on photos and digital images
					</div>
				</Slide>

				<Slide direction='up' duration={1500} triggerOnce={false} className="full-width textswaphome-descript">
					<div className="row">
					<div className="col s12 m4 center">
						<span className="textswaphome-em">Role</span> Full-stack developer
					</div>
					<div className="col s12 m4 center">
						<span className="textswaphome-em">Context</span> Application
					</div>
					<div className="col s12 m4 center">
						<span className="textswaphome-em">Period</span> Spring 2023
					</div>
					</div>
				</Slide>
			</div>

			<div className="textswaphome-intro">
				<div className="textswaphome-intro-container">
					<div className="textswaphome-intro-title"
						data-aos="flip-up"
						data-aos-delay="200"
					>
						Introduction
					</div>
					<div className="textswaphome-intro-content"
						data-aos="fade-up"
						data-aos-delay="200"
					>
						Image Text Editor allows users to edit texts on <span className="amber-text">real images</span> by ultilizing AI technologies such as <span className="amber-text">text detection, image inpainting, and font classification</span> to extract texts from images and edit them interactively.
					</div>

					<div className="center"
						data-aos="flip-right"
						data-aos-delay="300"
					>
						<Link className="textswaphome-intro-web pulse-btn" to="/image-text-editor-tool" target="_blank">
							Try It Now &nbsp;&nbsp;<BsBoxArrowUpRight />
						</Link>
					</div>

				</div>

			</div>

			<div className='textswaphome-title-container'>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					INFORMATION & ANALYSIS
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Project Details
				</div>
			</div>

			<div className="row textswaphome-section-container">
				<div className="col l5 m12"
					data-aos="fade-down"
					data-aos-delay="200"
				>
					<div className="section-slogan">
						Translate and edit text on your images
					</div>
					<div className="section-slogan-divider-left"></div>
					<div className="section-content">
						The system is designed to perform <span className="textswaphome-em-2">real-time text editting</span> on real images. It will automatically detect texts, fonts, translate to Vietnamese, inpaint the background, and make the detected texts editable.<br/><br/> This will open a wide range of applications such as <span className="textswaphome-em-2">manga translation, poster editing</span>, and much more.
					</div>
				</div>
				<div className="col l7 m12 center"
					data-aos="fade-up"
					data-aos-delay="200"
				>
					<video className="textswaphome-video-1" autoPlay={true} loop={true} muted={true} src="https://d22sdm4oybjqf2.cloudfront.net/ai-poster.webm"></video>
				</div>
			</div>

			<div className="row textswaphome-section-container">
				<div className="col l7 m12 center hide-on-med-and-down"
					data-aos="fade-up"
					data-aos-delay="200"
				>
					<video className="textswaphome-video-2 left-30" autoPlay={true} loop={true} muted={true} src="https://d22sdm4oybjqf2.cloudfront.net/valentine_mockup.webm"></video>
				</div>

				<div className="col l5 m12"
					data-aos="fade-down"
					data-aos-delay="200"
				>
					<div className="section-slogan text-right">
						Support text editing on perspective view
					</div>
					<div className="section-slogan-divider">
						<div className="section-slogan-divider-right"></div>
					</div>
					<div className="section-content">
						The tool also allows users to edit <span className="textswaphome-em-2">perspective warped text</span> in images. Instead of drawing a rectangle, users have to provide <span className="textswaphome-em-2">4 points</span> around the target text which will be used to warp the translated text later on.<br/><br/>
						However, this feature is only a <span className="textswaphome-em-2">beta version</span> and still in development.
					</div>
				</div>

				<div className="col l7 m12 center hide-on-large-only margin-top-40"
					data-aos="fade-up"
					data-aos-delay="200"
				>
					<video className="textswaphome-video-2" autoPlay={true} loop={true} muted={true} src="https://d22sdm4oybjqf2.cloudfront.net/valentine_mockup.webm"></video>
				</div>
			</div>

			<div className='textswaphome-title-container'>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					TECHNICAL INFORMATION
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Technology
				</div>
			</div>

			<div className="row textswaphome-section-container"
				data-aos="fade-up"
				data-aos-delay="200"
			>
				<div className="col l3 m6 s12 center">
					<a href="https://pytorch.org/" target="_blank">
						<img className="textswaphome-logo-img height-50" src={pytorch_icon} alt="PyTorch icon" />
					</a>
				</div>
				<div className="col l3 m6 s12 center">
					<a href="https://opencv.org/" target="_blank">
						<img className="textswaphome-logo-img height-70" src={opencv_icon} alt="opencv icon" />
					</a>
				</div>
				<div className="col l3 m6 s12 center">
					<a href="https://konvajs.org" target="_blank">
						<img className="textswaphome-logo-img height-70" src={konva_icon} alt="konva icon" />
					</a>
				</div>
				<div className="col l3 m6 s12 center">
					<a href="https://fastapi.tiangolo.com/" target="_blank">
						<img className="textswaphome-logo-img height-70" src={fastapi_icon} alt="fastapi icon" />
					</a>
				</div>
			</div>

			<div className='textswaphome-title-container no-margin-top'>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					Demonstration
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Demo & Code
				</div>
			</div>

			<div className="textswaphome-demo-container"
					data-aos="fade-down"
					data-aos-delay="200"
			>
				<div
					data-aos="flip-right"
					data-aos-delay="300"
				>
					<Link className="textswaphome-demo-btn text-pulse-btn" to="/image-text-editor-tool" target="_blank">
						Image Text Editor &nbsp;&nbsp;<AiOutlineEdit size={25}/>
					</Link>
				</div>

				<div
					data-aos="flip-right"
					data-aos-delay="300"
					onClick={() => logEvent(analytics, "imagetext_open_code")}
				>
					<a className="textswaphome-demo-code-btn text-pulse-btn" href="https://github.com/minhanh29/text-swap-api" target="_blank">
						View Source Code &nbsp;&nbsp;<AiFillGithub size={25}/>
					</a>
				</div>
			</div><br/>

			<div className="vertical-line"></div>
			<Recommend subtitle="NEXT PROJECT" title="Project YouHand" to="/youhand" />
			<Footer />
		</div>
	)
}

export default TextSwapHome
