import React, { useState, useEffect, useRef } from 'react'
import { RiSendPlaneFill } from "react-icons/ri"
import { VscChromeMinimize } from "react-icons/vsc"
import M from "materialize-css"
import axios from 'axios'

import BotResponse from "./BotResponse"
import UserResponse from "./UserResponse"

import "./chatbot.css"
import chatbot_icon from "../../images/chatbot.png"
import { CHATBOT_ENDPOINT } from "../../App"


const ROLE_CHATBOT = 0
const ROLE_USER = 1

const chat_data = [
	{
		role: ROLE_CHATBOT,
		message: "Hi. How can I help you?"
	},
]

const BotLoading = () => {
	return (
		<div className="bot-response-container">
			<div className="bot-response-ava">
				<img src={chatbot_icon} alt="ava" />
			</div>
			<div className="bot-response-message">
				<div className="typing">
				  <div className="dot"></div>
				  <div className="dot"></div>
				  <div className="dot"></div>
				</div>
			</div>
		</div>
	)
}

const Chatbot = () => {
	const container = useRef()
	const dialogRef = useRef()

	const [openChat, setOpenChat] = useState(false)
	const [openPopup, setOpenPopup] = useState(false)
	const [chatBotLoading, setChatBotLoading] = useState(false)
	const [dialog, setDialog] = useState(chat_data)
	const [text, setText] = useState("")
	const [sessionId, setSessionId] = useState("")
	const [ping, setPing] = useState(false)

	useEffect(() => {
		if (!ping) {
			pingAPI()
		}
	}, [])

	const pingAPI = async () => {
		try {
			await axios.get(CHATBOT_ENDPOINT + "/ping")
			setPing(true)
			setOpenPopup(true)
		} catch (e) {
			M.toast({html: e.message, classes: 'white red-text'});
		}
	}


	useEffect(() => {
		let obj = dialogRef.current
		if (obj) {
			obj.scrollTop = obj.scrollHeight
		}
	}, [dialog, openChat])

	useEffect(() => {
		window.visualViewport.addEventListener("resize", () => {
			if (!container.current) {
				return
			}

			if (window.innerWidth < 500) {
				container.current.style.height = "" + parseInt(window.visualViewport.height) + "px";
			} else {
				container.current.style.height = "500px"
			}
		});
	}, [])

	const getChatbotAnswer = async () => {
		try {
			const res = await axios.post(`${CHATBOT_ENDPOINT}/answer`, {
				session_id: sessionId,
				question: text
			})


			setDialog(prevState => [
				...prevState,
				{
					role: ROLE_CHATBOT,
					message: res.data.answer
				}
			])
			setSessionId(res.data.session_id)
		} catch (e) {
			setDialog(prevState => [
				...prevState,
				{
					role: ROLE_CHATBOT,
					message: "There is an unexpected error occured. Please check your Internet connection and try again later."
				}
			])
			setSessionId("")
		}
	}

	const updateMessage = async () => {
		if (text.trim() === "") {
			return
		}

		setDialog(prevState => [
			...prevState,
			{
				role: ROLE_USER,
				message: text
			}
		])
		setText("")

		setChatBotLoading(true)
		await getChatbotAnswer()
		setChatBotLoading(false)
	}

	const handleKeyDown = (e) => {
		if (e.key == "Enter") {
			if (e.shiftKey) {
				return
			}

			e.preventDefault()
			updateMessage()
		}
	}

	if (!openChat) {
		return (
			<div>
				{openPopup ?
				<div className="chatbot-popup-message-container">
					<div className="chatbot-popup-message">
						<div className="chatbot-popup-close cursor-pointer" onClick={() => setOpenPopup(false)}>
							<i className="material-icons">close</i>
						</div>
						Hi, I'm here to assist with any concerns you may have.
					</div>
				</div>: null}
				<div className="chatbot-icon" onClick={() => {
						setOpenChat(true);
						setOpenPopup(false);
					} }>
					<img src={chatbot_icon} alt="ava" />
				</div>
			</div>
		)
	}

	return (
		<div className="chatbot-container open-chat">
			<div className="chat-container" ref={container}>
				<div className="chatbot-header">
					<div className="chatbot-header-info">
						<div className="chatbot-header-ava">
							<img src={chatbot_icon} alt="ava" />
						</div>
						<div className="chatbot-header-name">
							Minh Anh Bot
						</div>
					</div>

					<div className="chatbot-header-close white-text" onClick={() => {
							setOpenChat(false)
						}}>
						<VscChromeMinimize size={25} />
					</div>
				</div>
				<div id="test" className="chatbot-dialog" ref={dialogRef}>
					{dialog.map(item => (
						item.role === ROLE_CHATBOT ? <BotResponse message={item.message} /> : <UserResponse message={item.message}/>
					))}
					{chatBotLoading ? <BotLoading /> : null}
				</div>
				<div className="chatbot-input">
					<textarea
						className="materialize-textarea"
						placeholder="Type your message here"
						value={text}
						onChange={e => setText(e.target.value)}
						onKeyDown={handleKeyDown}
					></textarea>
					<RiSendPlaneFill className="cursor-pointer chatbot-send-btn" size={27} onClick={updateMessage}/>
				</div>
			</div>
		</div>
	)
}

export default Chatbot
