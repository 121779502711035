import React, { useState, useEffect } from 'react'

import "./chatbot.css"
import chatbot_icon from "../../images/chatbot.png"

const UserResponse = ({ message }) => {
	return (
		<div className="user-response-container">
			<div className="user-response-message">
				{message}
			</div>
		</div>
	)
}

export default UserResponse
