import React, { useEffect } from 'react';
import { Slide } from 'react-awesome-reveal';
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebaseConfig"

import Recommend from '../../layout/Recommend'
import Footer from '../../layout/Footer'

import './timely.css'
import google_badge from '../../../images/google-play-badge.png'


const Timely = () => {

	useEffect(() => {
		logEvent(analytics, 'screen_view', {
			firebase_screen: "Timely",
			firebase_screen_class: "Timely"
		});
	}, [])

	return (
		<div className="overflow-x-hidden">
			<div className="timely-wallpaper overflow-x-hidden">
				<Slide direction='left' duration={1500} triggerOnce={true} className="full-width">
					<div className="timely-title">
						Timely Mobile
					</div>
				</Slide>
				<Slide direction='right' duration={1500} triggerOnce={true} className="full-width">
					<div className="timely-subtitle">
						Create your conflict-free timetables
					</div>
				</Slide>
				<Slide direction="up" duration={1500} triggerOnce={true} className="center container timely-btn-container">
					<div className="row">
					<div className="col s12 m12 l6 full-width">
					<a className="timely-intro-web pulse-btn" href="http://timelymobile.herokuapp.com/" target="_blank"
						onClick={() => logEvent(analytics, "timely_open_website")}
					>
						Visit Website
					</a>
					</div>
					<div className="col s12 m12 l6 full-width hide-on-small-only">
					<a href='https://play.google.com/store/apps/details?id=cszone.timely&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank'
						onClick={() => logEvent(analytics, "timely_download_google_play")}
					>
						<img className="google-badge" alt='Get it on Google Play' src={google_badge} />
					</a>
					</div>
					</div>
				</Slide>

				<Slide direction='up' duration={1500} triggerOnce={true} className="full-width timely-descript">
					<div className="row">
					<div className="col s12 m4 center">
						<span className="timely-em">Role</span> Full-stack developer
					</div>
					<div className="col s12 m4 center">
						<span className="timely-em">Context</span> Application
					</div>
					<div className="col s12 m4 center">
						<span className="timely-em">Period</span> Winter 2020
					</div>
					</div>
				</Slide>
			</div>
			<div className="section-slogan container">
				Content will be updated soon. Please visit Timely's official website to learn more about the project.
			</div>
			<Recommend subtitle="NEXT PROJECT" title="Image Text Editor" to="/image-text-editor" />
			<Footer />
		</div>
	)
}

export default Timely
