import { configureStore } from '@reduxjs/toolkit'
import textswapReducer from "./reducers/textswapSlice"
import fontReducer from "./reducers/fontSlice"
import toolReducer from "./reducers/toolSlice"

export const store = configureStore({
	reducer: {
		textswap: textswapReducer,
		font: fontReducer,
		tool: toolReducer,
	},
})
