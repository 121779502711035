import React, { useEffect, useState } from 'react'
import { BrowserRouter, useLocation, Switch, Route } from 'react-router-dom';
import axios from "axios"

import NavBar from './components/layout/NavBar'
import NotFound from './components/layout/NotFound'
import DashBoard from './components/dashboard/DashBoard'
import Contact from './components/contact/Contact'
import Chatbot from './components/chatbot/Chatbot'

import TextSwapHome from './components/projects/textswap/TextSwapHome'
import TextSwap from './components/projects/textswap/TextSwap'
import Youhand from './components/projects/youhand/Youhand'
import Timely from './components/projects/timely/Timely'
import Sudoku from './components/projects/sudoku/Sudoku'
import Omp from './components/projects/omp/Omp'


// export const CHATBOT_ENDPOINT = "http://localhost:8000"
export const CHATBOT_ENDPOINT = "https://minhanh-chatbot-kab4d3qdaq-de.a.run.app/"


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

	return <div></div>;
}

const DefaultContainer = () => (
	<div className="App full-height">
		<ScrollToTop />
		<NavBar />
		<Chatbot />

		<Switch className="full-height">
			<Route exact path="/" component={DashBoard} />
			<Route path="/image-text-editor" component={TextSwapHome} />
			<Route path="/youhand" component={Youhand} />
			<Route path="/omp" component={Omp} />
			<Route path="/timely" component={Timely} />
			<Route path="/sudoku" component={Sudoku} />
			<Route path="/contact" component={Contact} />
			<Route path="*" component={NotFound} />
		</Switch>
	</div>
 )

const App = () => {
	return (
		<BrowserRouter>
			<Switch className="full-height">
				<Route exact path="/image-text-editor-tool" component={TextSwap}/>
				<Route component={DefaultContainer}/>
			</Switch>
		</BrowserRouter>
	);
}

export default App;
