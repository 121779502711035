import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Transformer, Image as KonvaImage } from 'react-konva';
import Perspective from "perspectivejs"
import Konva from "konva"
import FontFaceObserver from "fontfaceobserver"

import { findFontSize } from "./Utils"

const PADDING = 100

const getContext = (canvas) => {
	const originalHeight = canvas.height;
	const originalWidth = canvas.width;

	canvas.width = originalWidth * window.devicePixelRatio;
	canvas.height = originalHeight * window.devicePixelRatio;

	canvas.style.width = `${originalWidth}px`;
	canvas.style.height = `${originalHeight}px`;

	let ctx = canvas.getContext("2d");
	ctx.mozImageSmoothingEnabled = false;
	ctx.webkitImageSmoothingEnabled = false;
	ctx.msImageSmoothingEnabled = false;
	ctx.imageSmoothingEnabled = false;

	return ctx
}

const TextPerspective = ({shapeProps, isSelected, onSelect, onChange}) => {
	/*
	 * Perspective Points must be in the form [[top, left], [top, right], [bottom, right], [bottom, left]]
	 * */

	const imgRef = useRef();
	const trRef = useRef();
	const [textImg, setTextImg] = useState(null)
	const [oldFont, setOldFont] = useState("Arial")
	const [isFontLoaded, setIsFontLoaded] = useState(false)

	useEffect(() => {
		setIsFontLoaded(false)
		new FontFaceObserver(shapeProps.textProps.fontFamily).load()
			.then(() => {setIsFontLoaded(true); setOldFont(shapeProps.textProps.fontFamily)})
			.catch((e) => {
				console.error(e.message);
			});
	}, [shapeProps.textProps.fontFamily]);


	useEffect(() => {
		if (isSelected) {
			// we need to attach transformer manually
			trRef.current.nodes([imgRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [isSelected]);

	useEffect(() => {
		skewText()
	}, [shapeProps, isFontLoaded])

	const skewText = () => {
		if (!isFontLoaded && shapeProps.foundFontSize)
			return

		let fontFamily = isFontLoaded ? shapeProps.textProps.fontFamily : oldFont
		let fontSize = shapeProps.textProps.fontSize

		if (!shapeProps.foundFontSize) {
			fontSize = findFontSize(
				shapeProps.textProps.text,
				shapeProps.textWidth,
				shapeProps.textHeight,
				shapeProps.textProps.align,
				fontFamily
			)

			if (isFontLoaded) {
				onChange({
					...shapeProps,
					textProps: {
						...shapeProps.textProps,
						fontSize,
					},
					foundFontSize: true
				})
			}
		}

		let textNode = new Konva.Text({
			...shapeProps.textProps,
			width: shapeProps.textWidth,
			height: shapeProps.textHeight,
			verticalAlign: "middle",
			fontFamily, fontSize
		})

		textNode.toBlob({
			callback: blob => {
				let URL = window.webkitURL || window.URL;
				const url = URL.createObjectURL(blob);
				let image = new Image();
				image.onload = () => {
					let canvas = document.createElement('canvas');
					canvas.width = image.width + PADDING * 2;
					canvas.height = image.height + PADDING * 2;

					let ctx = getContext(canvas)

					let p = new Perspective(ctx, image);
					let newPerspectivePoints = shapeProps.perspectivePoints.map(pt => [pt[0] + PADDING, pt[1] + PADDING])
					newPerspectivePoints = newPerspectivePoints.map(pt => [pt[0] * window.devicePixelRatio, pt[1] * window.devicePixelRatio])

					p.draw(newPerspectivePoints);
					let xList = newPerspectivePoints.map(pt => pt[0])
					let yList = newPerspectivePoints.map(pt => pt[1])
					let x1 = Math.min(...xList)
					let x2 = Math.max(...xList)
					let y1 = Math.min(...yList)
					let y2 = Math.max(...yList)
					let cropImg = ctx.getImageData(x1, y1, x2 - x1, y2 - y1)

					let canvas2 = document.createElement('canvas');
					canvas2.width = cropImg.width / window.devicePixelRatio
					canvas2.height = cropImg.height / window.devicePixelRatio
					let ctx2 = getContext(canvas2)
					ctx2.mozImageSmoothingEnabled = false;
					ctx2.webkitImageSmoothingEnabled = false;
					ctx2.msImageSmoothingEnabled = false;
					ctx2.imageSmoothingEnabled = false;

					ctx2.putImageData(cropImg, 0, 0)

					// scale to original ratio
					setTextImg(canvas2)
				}
				image.src = url
			},
			pixelRatio: 2,
			imageSmoothingEnabled: false,
		})
	}

	return (
	<Fragment>
		<KonvaImage
			id={"text_" + shapeProps.id}
			x={shapeProps.x}
			y={shapeProps.y}
			width={shapeProps.width}
			height={shapeProps.height}
			fill="transparent"
			image={textImg}
			ref={imgRef}
			onClick={onSelect}
			onTap={onSelect}
			draggable={isSelected}
			onDragEnd={e => {
				onChange({
					...shapeProps,
					x: e.target.x(),
					y: e.target.y()
				});
			}}
			onTransformEnd={e => {
				// transformer is changing scale of the node
				// and NOT its width or height
				// but in the store we have only width and height
				// to match the data better we will reset scale on transform end
				const node = imgRef.current;
				const scaleX = node.scaleX();
				const scaleY = node.scaleY();

				// we will reset it back
				node.scaleX(1);
				node.scaleY(1);
				onChange({
					...shapeProps,
					x: node.x(),
					y: node.y(),
					// set minimal value
					width: Math.max(5, node.width() * scaleX),
					height: Math.max(5, node.height() * scaleY),
					textWidth: Math.max(5, shapeProps.textWidth * scaleX),
					textHeight: Math.max(5, shapeProps.textHeight * scaleY),
				});
			}}
		/>
		{isSelected && <Transformer
			ref={trRef}
			boundBoxFunc={(oldBox, newBox) => {
				// limit resize
				if (newBox.width < 5 || newBox.height < 5) {
					return oldBox;
				}
				return newBox;
			}}
		/>}
	</Fragment>
	)
}

export default TextPerspective

