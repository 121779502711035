import React, { Component } from 'react';
import { IoLogoFacebook, IoLogoYoutube, IoLogoLinkedin, IoLogoGithub } from "react-icons/io";

import TextScramble from '../utils/TextScramble'
import ConsoleText from '../utils/ConsoleText'
import MyParticles from './MyParticles'

import './dashboard.css'
import logo from '../../images/logo_big.png'


class Wallpaper extends Component {
	state = {
		logoStyle: 'bg-logo hidden',
		displayLogo: false
	}

	componentDidMount() {
		if (this.state.displayLogo) {
			return
		}

		setTimeout(this.showLogo, 1250)
		this.setState({
			displayLogo: true,
		})
	}

	showLogo = () => {
		this.setState({
			logoStyle: 'bg-logo',
		})
	}

	// move to the project section
	handleStart = () => {
		// the wallpaper height
		let wp_height = document.getElementById('wallpaper').clientHeight;

		// the distance to move to
		window.scrollTo({
			top: wp_height,
			behavior: 'smooth',
		});
	}

	render() {
		const showCanvas = window.width > 600;
		return (
			<div className="bg-dark full-height" id="wallpaper">
				<div className="white-text">
					<div>
						<img src={logo} className={this.state.logoStyle} alt="my logo" />
						<div className="bg-logo-overlay"></div>
					</div>

					<div className="my-info">
						<div
							data-aos="fade-right"
							data-aos-delay={800}
						>
							<ConsoleText
								word="Nguyen Minh Anh"
								className="my-name"
								delay={1000}
							/>
						</div>

						<div
							data-aos="fade-right"
							data-aos-delay={1000}
						>
							<TextScramble
								oldText="Who am I?"
								text="Artificial Intelligence Engineer"
								delay={3000}
								className="subtitle"
							/>
						</div>
						<div className="blank-space"></div>

						<div
							data-aos="fade-right"
							data-aos-delay={1200}
							data-aos-once="true"
						>
							<div
								className="cta"
								onClick={this.handleStart}
							>
								<span >Get Started</span>
								<svg width="13px" height="10px" viewBox="0 0 13 10">
									<path d="M1,5 L11,5"></path>
									<polyline points="8 1 12 5 8 9"></polyline>
								</svg>
							</div>
						</div>
					</div>

				</div>

				<div
					className="social-bar hide-on-med-and-down"
					data-aos="fade-left"
					data-aos-delay={1500}
				>
					<a href="https://github.com/minhanh29" target="_blank">
						<IoLogoGithub size={70} className="social-logo"/>
					</a>
					<a href="https://www.facebook.com/minhanh.cszone" target="_blank">
						<IoLogoFacebook size={70} className="social-logo"/>
					</a>
					<a href="https://www.linkedin.com/in/minh-anh-nguy%E1%BB%85n-b80aa0152/" target="_blank">
						<IoLogoLinkedin size={70} className="social-logo"/>
					</a>
					<a href="https://www.youtube.com/channel/UCyafRmRiKO2bOtm0MXQ_LOA" target="_blank">
						<IoLogoYoutube size={65} className="social-logo"/>
					</a>
				</div>
				{/* { showCanvas ?  <MyParticles /> : <div></div> } */}
			</div>
		)
	}
}

export default Wallpaper

