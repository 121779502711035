import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom'
import { Slide } from 'react-awesome-reveal';
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebaseConfig"

import Wallpaper from './Wallpaper'
import SumProject from './SumProject'
import SumAbout from './SumAbout'
import Recommend from '../layout/Recommend'
import Footer from '../layout/Footer'

import './dashboard.css'

const Dashboard = () => {
	const location = useLocation()

	useEffect(() => {
		logEvent(analytics, 'screen_view', {
			firebase_screen: "Dashboard",
			firebase_screen_class: "Dashboard"
		});

	}, [])

	useEffect(()=> {
		if (location.hash) {
			let elem = document.getElementById(location.hash.slice(1))
			if (elem) {
				elem.scrollIntoView({behavior: "smooth"})
			}
		} else {
		window.scrollTo({top:0,left:0, behavior: "smooth"})
		}
    }, [location,])

	return (
		<div className="full-height">
			<div className="overlay"></div>
			<Slide direction='left' className="full-height overflow-x-hidden overflow-y-hidden" duration={1500} triggerOnce={true}>
				<Wallpaper />
			</Slide>
			<div className="overflow-x-hidden" id="my-projects">
				<SumProject />
			</div>

			<SumAbout />
			<div className="vertical-line"></div>

			<Recommend subtitle="LEARN MORE" title="Contact Me" to="/contact" />
			<Footer />
		</div>
	)
}

export default Dashboard
