import React, { Component } from 'react'
import ava from '../../images/ava_with_robot.png'
import { ref, getDownloadURL } from "firebase/storage";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebaseConfig"
import { storage } from "../../firebaseConfig"
import M from "materialize-css"
import "./sum_about.css"

const SumAbout = () => {
	const downloadCV = () => {
		logEvent(analytics, 'cv_download');

		const starsRef = ref(storage, 'CV_Nguyen_Minh_Anh.pdf');
		// Get the download URL
		getDownloadURL(starsRef)
			.then((url) => {
				let link = document.createElement("a");
				link.download = 'CV_Nguyen_Minh_Anh.pdf';
				link.href = url;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((error) => {
				// A full list of error codes is available at
				// https://firebase.google.com/docs/storage/web/handle-errors
				switch (error.code) {
					case "storage/object-not-found":
						// File doesn't exist
						M.toast({html: "File does not exist", classes: 'red white-text'});
						break;
					case "storage/unauthorized":
						// User doesn't have permission to access the object
						M.toast({html: "Permission Denied", classes: 'red white-text'});
						break;
					case "storage/unknown":
						// Unknown error occurred, inspect the server response
						M.toast({html: "Unexpected Error", classes: 'red white-text'});
						break;
					default:
						break;
				}
			});
	}

	return (
		<div className="sum-about-container">
			<div className="sum-about-title-container">
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					QUICK INTRO
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					About Me
				</div>
			</div>

			<div className="row">
				<div className="col l5 m12"
					data-aos="fade-down"
					data-aos-delay="200"
				>
					<div className="sum-about-slogan">
						An enthusiastic artificial intelligence engineer from Vietnam
					</div>
					<div className="sum-about-divider"></div>
					<div className="sum-about-info">
						My name is Nguyen Minh Anh. I am a Vietnamese student pursuing a Bachelor of Information Technology at RMIT University, Vietnam.<br/><br/>
						My passion is building software and solving tough programming problems. Moreover, I am aslo interested in developing games and creating AI models. Please feel free to contact me if you are looking for someone to hire or collaborate with in the field.
					</div>

					<div className="pulse" onClick={downloadCV}>
						Download CV
					</div>
				</div>

				<div className="col l7 m12"
					data-aos="fade-up"
					data-aos-delay="200"
				>
					<img className="sum-about-ava" src={ava} alt="Minh Anh ava" />
				</div>
			</div>
		</div>
	)
}

export default SumAbout
