import React, { Fragment, useState, useRef, useEffect } from 'react';
import useImage from 'use-image';
import { Text, Transformer } from 'react-konva';
import FontFaceObserver from "fontfaceobserver"

import { findFontSize } from "./Utils"
// import sample_img from "../../../images/sample_texture2.jpeg"

const TextTransformer = ({shapeProps, isSelected, onSelect, onChange}) => {
	const shapeRef = useRef();
	const trRef = useRef();
	const [oldFont, setOldFont] = useState("Arial")
	const [isFontLoaded, setIsFontLoaded] = useState(false)
	// const [image] = useImage(sample_img);
	const [fontSize, setFontSize] = useState(12)
	useEffect(() => {
		setIsFontLoaded(false)
		new FontFaceObserver(shapeProps.textProps.fontFamily).load()
			.then(() => {setIsFontLoaded(true); setOldFont(shapeProps.textProps.fontFamily)})
			.catch((e) => {
				console.error(e.message);
			});
	}, [shapeProps.textProps.fontFamily]);

	useEffect(() => {
		if (!isFontLoaded && shapeProps.foundFontSize)
			return

		let fontFamily = isFontLoaded ? shapeProps.textProps.fontFamily : oldFont
		let fontSize = shapeProps.textProps.fontSize

		if (!shapeProps.foundFontSize) {
			fontSize = findFontSize(
				shapeProps.textProps.text,
				shapeProps.textWidth,
				shapeProps.textHeight,
				shapeProps.textProps.align,
				fontFamily
			)

			if (isFontLoaded) {
				onChange({
					...shapeProps,
					textProps: {
						...shapeProps.textProps,
						fontSize,
					},
					foundFontSize: true
				})
			}
		}

		setFontSize(fontSize)
	}, [shapeProps, isFontLoaded])

	useEffect(() => {
		if (isSelected) {
			// we need to attach transformer manually
			trRef.current.nodes([shapeRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [isSelected]);
	// console.log(shapeProps && image ? shapeProps.width/image.width : 1)

	// if (!image) {
	// 	return <Fragment></Fragment>
	// }

	return (
	<Fragment>
		<Text
			id={"text_" + shapeProps.id}
			verticalAlign="middle"
			onClick={onSelect}
			onTap={onSelect}
			ref={shapeRef}
			{...shapeProps.textProps}
			fontFamily={isFontLoaded ? shapeProps.textProps.fontFamily : "Arial"}
			x={shapeProps.x}
			y={shapeProps.y}
			width={shapeProps.width}
			height={shapeProps.height}
			fontSize={fontSize}
			draggable={isSelected}
			// fillPatternImage={image}
			// fillPatternRepeat="repeat"
			// fillPatternScaleX={shapeProps.width/image.width}
			// fillPatternScaleY={shapeProps.width/image.width}
			onDragEnd={e => {
				onChange({
					...shapeProps,
					x: e.target.x(),
					y: e.target.y()
				});
			}}
			onTransformEnd={e => {
				// transformer is changing scale of the node
				// and NOT its width or height
				// but in the store we have only width and height
				// to match the data better we will reset scale on transform end
				const node = shapeRef.current;
				const scaleX = node.scaleX();
				const scaleY = node.scaleY();

				// we will reset it back
				node.scaleX(1);
				node.scaleY(1);
				onChange({
					...shapeProps,
					x: node.x(),
					y: node.y(),
					// set minimal value
					width: Math.max(5, node.width() * scaleX),
					height: Math.max(node.height() * scaleY)
				});
			}}
		/>
		{isSelected && <Transformer
			ref={trRef}
			boundBoxFunc={(oldBox, newBox) => {
				// limit resize
				if (newBox.width < 5 || newBox.height < 5) {
					return oldBox;
				}
				return newBox;
			}}
		/>}
	</Fragment>
	)
}

export default TextTransformer
